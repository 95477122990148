import '../assets/fomantic/dist/semantic.css';
import { Container, Table } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import {Helmet} from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew } from '../utils/utils';
import { getAssetURL } from '../App';

export const Events = (props: {
    navigation: () => void;
}) => {
    const [crew, setCrew] = React.useState<NamerCrew[]|undefined>(undefined);
    const [events, setEvents] = React.useState<Map<number,EventInfo>>(undefined);

    const calcSuccess = (skill: number) => {
        skill *= 2;
        return Math.floor(10000/(1 + Math.exp(3.5 * (0.5 - skill/2000))))/100;
    }
    //const cSkills = ["cmd","dip","eng","med","sci","sec"]

    React.useEffect(() => {
        props.navigation();
        if (!events) {
            getEventInfo().then((data) => setEvents(data));
        }
        if (!crew) {
            getCrew().then((data) => {
                if (data && data.length > 0) {
                    // data = data.filter(x=>x.rarity>=4);
                    data.forEach(x=> {
                        x.triplet = x.skills.join("/");
                        x.trait = x.traits ? x.traits.length : 0;
                        x.dip = x.baseScore["dip"]
                        x.sec = x.baseScore["sec"]
                        x.sci = x.baseScore["sci"]
                        x.eng = x.baseScore["eng"]
                        x.med = x.baseScore["med"]
                        x.cmd = x.baseScore["cmd"]
                        x.priSec = x.bPScore[0]
                        x.priTer = x.bPScore[1]
                        x.secTer = x.bPScore[2]
                        x.factions = x.eCounts["F"] ?? 0
                        x.factgal = (x.eCounts["F/G"] ?? 0) + (x.eCounts["G/F"] ?? 0)
                        x.galaxies = x.eCounts["G"] ?? 0
                        x.eSuccess = new Map<string,number>();
                        for (const base of Object.keys(x.baseScore)) {
                            x.eSuccess[base] = calcSuccess(x.baseScore[base]);
                        }
                    });
                    data.sort((a,b) => b.rarity-a.rarity || a.eRatingR-b.eRatingR);
                    setCrew(data);
                }            
            });
        }
    }, [])

    if (crew == undefined || events == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const eventTypes = new Map([
        ["F", "Faction"],
        ["G", "Galaxy"],
        ["S", "Skirmish"],
        ["E", "Expedition"],
        ["F/G", "Faction/Galaxy"],
        ["G/F", "Galaxy/Faction"],
        ["F/S", "Faction/Skirmish"],
        ["S/F", "Skirmish/Faction"],
        ["F/E", "Faction/Expedition"],
        ["E/F", "Expedition/Faction"],
        ["G/S", "Galaxy/Skirmish"],
        ["S/G", "Skirmish/Galaxy"],
        ["S/E", "Skirmish/Expedition"],
        ["E/S", "Expedition/Skirmish"],
        ["E/F/G", "Expedition/Faction/Galaxy"],
        ["F/G/E", "Faction/Galaxy/Expedition"]
    ])

    const tableConfig: ITableConfigRow[] = [
        { width: 7, column: 'name', title: 'Crew', pseudocolumns: ['name', 'trait'] },
        { width: 1, column: 'eRatingR', title: 'Rating', class: 'row-header'},,
        { width: 1, column: 'events', title: 'Events', secondaryColumn: 'eRating', class: 'row-header'},
        { width: 1, column: 'factions', title: 'Faction', secondaryColumn: 'eRating', class: 'row-header'},
        { width: 1, column: 'factgal', title: 'F/G', secondaryColumn: 'eRating', class: 'row-header'},
        { width: 1, column: 'galaxies', title: 'Galaxy', secondaryColumn: 'eRating', class: 'row-header'},
        { width: 1, column: 'cmd', title: <img width='14' src={getAssetURL('icons_icon_command_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'dip', title: <img width='14' src={getAssetURL('icons_icon_diplomacy_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'eng', title: <img width='14' src={getAssetURL('icons_icon_engineering_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'med', title: <img width='14' src={getAssetURL('icons_icon_medicine_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sci', title: <img width='14' src={getAssetURL('icons_icon_science_skill.png')}/>, class: 'row-header'},
        { width: 1, column: 'sec', title: <img width='14' src={getAssetURL('icons_icon_security_skill.png')}/>, class: 'row-header'},
        // { width: 1, column: 'priSec', title: 'PriSec', class: 'row-header'},
        // { width: 1, column: 'priTer', title: 'PriTer', class: 'row-header'},
        // { width: 1, column: 'secTer', title: 'SecTer', class: 'row-header'},     
    ]

    const descriptionLabel = (crew: NamerCrew) => {
        if (isMobile && !isTablet) {
            return <span style={{fontSize:"small"}}>{crew.traitsNamed.length > 1 ? crew.traitsNamed.length + " traits" : "1 trait"}</span>
        }
        return <span style={{fontSize:"small"}}>{crew.traitsNamed.map(x=>x.replaceAll("_", " ")).join(", ")}</span>
        // if (crew.collections) {
        //     if (crew.collections.length > 1) {
        //         return crew.collections.length + " collections"
        //     } else {
        //         return "1 collection"
        //     }
        // }
        // return ""
    }

  const renderTableRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '60px auto',
                        gridTemplateAreas: `'icon stats' 'icon description'`,
                        gridGap: '1px'
                    }}
                >
                    <div style={{ gridArea: 'icon' }}>
                        <img width={48} src={getAssetURL(crew.portrait)} />
                    </div>
                    <div style={{ gridArea: 'stats' }} className={rarityClass(crew.rarity)}>
                        <Link className={rarityClass(crew.rarity)}
                            to={"/crew/"+crew.symbol}
                            style={{ fontWeight: 'bolder', fontSize: '1.25em' }}
                            onClick={()=>logEvent("CrewLink", "event", crew.symbol)}>
                            {crew.name}
                        </Link>
                    </div>
                    <div style={{ gridArea: 'description' }}>{descriptionLabel(crew)}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <b>#{crew.eRatingR}</b><br />
                <small style={{ fontSize: '100%' }}>{crew.eRating}</small>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.events}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {crew.factions}
            </Table.Cell>
            <Table.Cell key='totalF' textAlign='center'>
                {crew.factgal}
            </Table.Cell>
            <Table.Cell key='totalG' textAlign='center'>
                {crew.galaxies}
            </Table.Cell>
            {crew.cmd > 0 ? (
                <Table.Cell key='cmd' textAlign='center'>
                    <b>{crew.cmd.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["cmd"]}%<br/>#{crew.bRank["cmd"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='cmd' />
            )}
            {crew.dip > 0 ? (
                <Table.Cell key='dip' textAlign='center'>
                    <b>{crew.dip.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["dip"]}%<br/>#{crew.bRank["dip"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='dip' />
            )}
            {crew.eng > 0 ? (
                <Table.Cell key='eng' textAlign='center'>
                    <b>{crew.eng.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["eng"]}%<br/>#{crew.bRank["eng"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='eng' />
            )}
            {crew.med > 0 ? (
                <Table.Cell key='med' textAlign='center'>
                    <b>{crew.med.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["med"]}%<br/>#{crew.bRank["med"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='med' />
            )}
            {crew.sci > 0 ? (
                <Table.Cell key='sci' textAlign='center'>
                    <b>{crew.sci.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["sci"]}%<br/>#{crew.bRank["sci"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sci' />
            )}
            {crew.sec > 0 ? (
                <Table.Cell key='sec' textAlign='center'>
                    <b>{crew.sec.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.eSuccess["sec"]}%<br/>#{crew.bRank["sec"]}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='sec' />
            )}
            {/* {crew.priSec > 0 ? (
                <Table.Cell key='priSec' textAlign='center'>
                    <b>{crew.priSec.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[0]}<br/>{crew.bPRank[0] != 0 ? "#"+crew.bPRank[0] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='priSec' />
            )}
            {crew.priTer > 0 ? (
                <Table.Cell key='priTer' textAlign='center'>
                    <b>{crew.priTer.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[1]}%<br/>{crew.bPRank[1] != 0 ? "#"+crew.bPRank[1] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='priTer' />
            )}
            {crew.secTer > 0 ? (
                <Table.Cell key='secTer' textAlign='center'>
                    <b>{crew.secTer.toFixed(0)}</b>
                    <br />
                    <small style={{ fontSize: '80%', color: 'darkgray' }}>{crew.bPSuccess[2]}%<br/>{crew.bPRank[2] != 0 ? "#"+crew.bPRank[2] : ""}</small>
                </Table.Cell>
            ) : (
                <Table.Cell key='secTer' />
            )} */}
            {/*cSkills.map(skill => {
                let index = -1;
                Object.keys(crew.gScoreBySkill).forEach((k, idx) => {
                    if (k == skill) {
                        index = idx;
                    }
                })
                return index > -1 ? (
                    <Table.Cell key={skill} textAlign='center'>
                        <b>{Math.floor(Object.values(crew.gScoreBySkill)[index])}</b>
                        <br />
                        ({Math.floor(Object.values(crew.gMinBySkill)[index])}-{Math.floor(Object.values(crew.gMaxBySkill)[index])})
                    </Table.Cell>
                ) : (
                    <Table.Cell key={skill} />
                )
            })*/}
        </React.Fragment>
    );
  }

  const renderExpandedRow = (crew: NamerCrew, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell colSpan={15} className="expanded-cell">
                <EventStats events={events} crew={crew} showAd={true}/>
            </Table.Cell>
        </React.Fragment>
    )
  }

  const title = "Event ratings - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/ratings/event";
  const description = "Star Trek Timelines event ratings based on historical event data and shuttle seat frequency";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <SearchableTable
            id="event_crew"
            data={crew}
            config={tableConfig}
            renderTableRow={(crew, idx) => renderTableRow(crew, idx)}
            renderExpandedRow={(crew, idx) => renderExpandedRow(crew, idx)}
            filterRow={showThisCrew}
            showFilterOptions={false}
            showSearchExplanation={true}
            querySuggestions={true}
        />
        <br/>
        <p>
            Note: These ratings take into account event shuttle success chances and base ranks on every seat for every event trait, mega or crew variant. Historical event shuttle seat frequency data is taken into account. Blanket Discovery mega events -- in which all crew with the 'dsc' hidden trait get a bonus -- are unlikely to happen again so they only contribute by a quarter of their real value.
        <br/>
            Success chances that are on display are calculated with the minor event bonus (x2). 
        </p>
        <Footer/>
    </div>
  );
}

export default Events;