import '../../assets/fomantic/dist/semantic.css';
import { List, Statistic, Divider, Image } from 'semantic-ui-react';
import React from 'react';
import ReactGA from "react-ga4";
import { Link } from "@reach/router";
import AdSense from 'react-adsense';

import '../../App.css';
import { NamerCrew, EventInfo, getEventInfo } from '../../services/NamerStats';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { logEvent } from '../../utils/utils';
import { getAssetURL } from '../../App';

export const EventStats = (props: {
    events?: Map<number,EventInfo>;
    crew: NamerCrew;
    showAd?: boolean;
}) => {
    const isOnlyMobile = isMobile && !isTablet;
    const eventTypes = new Map([
        ["F", "Faction"],
        ["G", "Galaxy"],
        ["S", "Skirmish"],
        ["E", "Expedition"],
        ["F/G", "Faction/Galaxy"],
        ["G/F", "Galaxy/Faction"],
        ["F/S", "Faction/Skirmish"],
        ["S/F", "Skirmish/Faction"],
        ["F/E", "Faction/Expedition"],
        ["E/F", "Expedition/Faction"],
        ["G/S", "Galaxy/Skirmish"],
        ["S/G", "Skirmish/Galaxy"],
        ["S/E", "Skirmish/Expedition"],
        ["E/S", "Expedition/Skirmish"],
        ["E/F/G", "Expedition/Faction/Galaxy"],
        ["F/G/E", "Faction/Galaxy/Expedition"]
    ])

    const traitIcon = (trait:string) => {
        if (trait === "physician") {
            return getAssetURL("items_keystones_doctor.png")
        } else {
            return getAssetURL("items_keystones_"+trait+".png")
        }
    }

    const [events, setEvents] = React.useState<Map<number,EventInfo>>(props.events);
    React.useEffect(() => {
        if (!events) {
            getEventInfo().then((data) => setEvents(data));
        }
    }, []);

    if (!events) {
        return <p>Loading event data...</p>
    }

    let perYear = new Map<number,EventInfo[]>();
    let years: number[] = []
    if (props.crew.eLog) {
        props.crew.eLog.forEach((event, idx) => {
            if (events[event.id] === undefined) {
                return;
            }
            let year = new Date(events[event.id].start).getFullYear();
            if (!perYear[year]) {
                perYear[year] = [];
            }
            perYear[year].unshift(event);
            if (!years.find(x=>x===year)) {
                years.unshift(year);
            }
        });
    }

    const getTraits = () => {
        return <List.Item>
            <List.Icon name='heartbeat' size='large' verticalAlign='top' />
            <List.Content>
                <List.Header>Traits</List.Header>
                <List.Description>
                    {props.crew.traitsNamed.map((x,idx)=><span key={"t"+idx}>
                        <Link className="blueLink"
                            to={"/ratings/overall?search="+encodeURIComponent(x)}
                            onClick={()=>logEvent("SearchType", "trait", x)}>
                            {x}
                        </Link>
                        {idx===props.crew.traitsNamed.length-1?"":", "}
                    </span>)}
                    <br/>
                    <i>{props.crew.hiddenTraits.map((x,idx)=><span key={"ht"+idx}>
                        <Link className="blueLink"
                            to={"/ratings/overall?search="+encodeURIComponent(x)}
                            onClick={()=>logEvent("SearchType", "HiddenTrait", x)}>
                            {x}
                        </Link>
                        {idx===props.crew.hiddenTraits.length-1?"":", "}</span>)}</i>
                </List.Description>
            </List.Content>
        </List.Item>
    }

    if (!props.crew.eLog || props.crew.eLog.length === 0) {
        return <List divided relaxed>
            {getTraits()}   
            <List.Item>
                <p> No events :(</p>
            </List.Item>
        </List>
    }

    const getEventTraitHeader = (letter, nonbonus, trait) => {
        let style = {};
        if (isOnlyMobile && letter) {
            style = {marginRight:"5px"}
        }
        return <React.Fragment>
            {!letter && !nonbonus && <Image avatar className="er-traitimg" src={traitIcon(trait)} />}
            {letter && <Image><List.Header className="center" style={style}>{letter}</List.Header></Image>}
            {nonbonus && <List.Icon name='info' size='large' verticalAlign='top' />}
        </React.Fragment>
    }

    const getEventTraits = () => {
        let style = {};
        if (isOnlyMobile) {
            style = { marginTop: "10px"}
        }
        return <React.Fragment>
            {props.crew.eTraits.map((trait, idx) => {
                let letter: string | undefined;
                let nonbonus: boolean = false
                if (trait === "tosbridge" || trait === "tos") {
                    letter = "TOS"
                } else if (trait === "dsc") {
                    letter = "DSC"
                } else if (trait === "ent") {
                    letter = "ENT"
                } else if (trait === "tng") {
                    letter = "TNG"
                } else if (trait === "voy") {
                    letter = "VOY"
                } else if (trait === "pic") {
                    letter = "PIC"
                } else if (trait === "ds9") {
                    letter = "DS9"
                } else if (trait === "low") {
                    letter = "LDS"
                } else if (trait === "vst") {
                    letter = "VST"
                } else if (trait === "tas") {
                    letter = "TAS"
                } else if (trait === "snw") {
                    letter = "SNW"
                } else if (trait === "original") {
                    letter = "ORG"
                } else if (trait === "_nonbonus") {
                    letter = undefined
                    nonbonus = true
                } else if (!props.crew.traits.find(x=>x===trait)) {
                    letter = "VAR"
                }
                return (<List.Item className="er-trait" style={style} key={"e"+idx}>
                    {!isOnlyMobile && getEventTraitHeader(letter, nonbonus, trait)}
                    <List.Content>
                        <List.Header>{isOnlyMobile && getEventTraitHeader(letter, nonbonus, trait)} #{props.crew.eTraitR[idx]}{' '}
                            {nonbonus?" by performance as a non-bonus crew":
                                <Link className="blueLink"
                                    to={"/ratings/event?search="+encodeURIComponent(trait)}
                                    onClick={()=>logEvent("SearchType", "EventTrait", trait)}>
                                    {trait}
                                </Link>}
                            {' - '}{props.crew.eTraitP[idx]}%
                        </List.Header>
                        <List.Description>
                            {props.crew.eBRanks[idx].join(", ")}
                        </List.Description>
                    </List.Content>
                </List.Item>)}
            )}
        </React.Fragment>
    }

    const getEventHistory = () => {
        let style = {}
        if (isOnlyMobile) {
            style = {
                marginLeft: '10px',
                marginBottom: '5px',
                marginTop: '2px'
            }
        }
        return <React.Fragment>
            {years.map((year, idx) =>
                <List.Item key={"li"+idx}>
                    {!isOnlyMobile && <Image><List.Header>{year}</List.Header></Image>}
                    {isOnlyMobile && <span className="centered" style={{marginLeft:"100px"}}><b>{year}</b></span>}
                    <List.Content>
                        <List.Description>
                            {perYear[year].map((event, idx) => {
                                let evtInfo = events[event.id];
                                return (<div style={style} key={"de"+idx}>
                                    <span className="mono">
                                        {new Date(evtInfo.start).toLocaleDateString("en-US", {month:"short", day:"2-digit"})}
                                    </span>
                                    {/* {' - '}<a className="blueLink" href={"https://sttwiki.org/wiki/"+encodeURIComponent(evtInfo.name)} target="_blank" rel="noreferrer noopener"
                                        onClick={() => {ReactGA.event({category:"ExternalLink",action:"WikiEvent",label:evtInfo.name})}}>
                                        {evtInfo.name}
                                    </a> ({evtInfo.type}) */}
                                    {' - '}<Link className="blueLink" to={"/event/"+event.id}
                                        onClick={()=>logEvent("EventLink", "events", event.id.toString())}>
                                            {evtInfo.name}
                                    </Link> ({evtInfo.type})
                                    {event.f && <span> - <b style={{color:"white"}}>featured</b></span>}
                                    {event.v && <span> - <b style={{color:"silver"}}>variant</b></span>}
                                    {event.t && <span> - {event.t}</span>}
                                </div>)         
                            })}
                        </List.Description>
                    </List.Content>
                </List.Item>
            )}
        </React.Fragment>
    }

    return (
        <List divided relaxed>
            {getTraits()}   
            <List.Item>
                <List.Icon name='chart bar outline' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Description>
                        <Statistic.Group inverted size="mini" className="er-count">
                            {Object.keys(props.crew.eCounts).map((type, idx) => 
                                <React.Fragment key={idx}>
                                    <Statistic inverted size="tiny" key={"ecount"+idx}>
                                        <Statistic.Label>{eventTypes.get(type)}</Statistic.Label>
                                        <Statistic.Value>{props.crew.eCounts[type]}</Statistic.Value>
                                    </Statistic>
                                    <Divider/>
                                </React.Fragment>
                            )}
                        </Statistic.Group>
                    </List.Description>
                </List.Content>
            </List.Item>
        
            {props.crew.eTraits && props.crew.eTraits.length > 0 && <List.Item>
                <List.Icon name='trophy' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>Event rating contributions by share</List.Header>
                    <List.Description>
                        The following rank numbers are strictly per trait
                        <List>
                            {!isOnlyMobile && getEventTraits()}
                        </List>
                    </List.Description>
                </List.Content>
                {isOnlyMobile && getEventTraits()}
            </List.Item>}

            <List.Item>
                <List.Icon name='history' size='large' verticalAlign='top' />
                <List.Content>
                    <List.Header>Event history</List.Header>
                    <List.Description>
                        <List>
                            {!isOnlyMobile && getEventHistory()}
                        </List>
                    </List.Description>
                </List.Content>
                {isOnlyMobile && getEventHistory()}
            </List.Item>
            {props.showAd && <div className="adslot">
                {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
                {isMobileOnly && <AdSense.Google
                    className="ad"
                    client='ca-pub-5995456634193629'
                    slot='6710934611'
                    style={{ display: 'block'}}
                    format='auto'
                    responsive='true'
                />}
                {!isMobileOnly && <AdSense.Google
                    className="ad adfixed"
                    client='ca-pub-5995456634193629'
                    slot='1841751316'
                    style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                    format='fixed'
                />}
            </div>}
        </List>)
  }

  export default EventStats;