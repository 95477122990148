import { StatType } from "../components/FeaturedCrew";
import { FullEventInfo, NamerCrew, Quipment, ShipAction, ShipInfo } from "../services/NamerStats";
import ReactGA from "react-ga4";
import { JsxElement } from "typescript";
import React from "react";
import { getAssetURL } from "../App";

export function logEvent(category:string, action:string, label?:string) {
    ReactGA.event({category, action, label});
}

export function getColorForPercentage(pct: number, type?: StatType, rarity?: number) {
    let percentColors = [
        { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
        { pct: 0.436, color: { r: 0xff, g: 0x40, b: 0 } }, //30
        { pct: 0.476, color: { r: 0xff, g: 0x80, b: 0 } }, //50
        { pct: 0.581, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
        { pct: 0.667, color: { r: 0xff, g: 0xff, b: 0 } }, //80
        { pct: 0.783, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
        { pct: 0.836, color: { r: 0x80, g: 0xff, b: 0 } }, //95
        { pct: 0.934, color: { r: 0x40, g: 0xff, b: 0 } }, //99
        { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100

    if (type === StatType.Gauntlet) {
        percentColors = [
            { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
            { pct: 0.551, color: { r: 0xff, g: 0x40, b: 0 } }, //80
            { pct: 0.585, color: { r: 0xff, g: 0x80, b: 0 } }, //85
            { pct: 0.622, color: { r: 0xff, g: 0xc0, b: 0 } }, //90
            { pct: 0.651, color: { r: 0xff, g: 0xff, b: 0 } }, //93
            { pct: 0.69, color: { r: 0xc0, g: 0xff, b: 0 } }, //95
            { pct: 0.733, color: { r: 0x80, g: 0xff, b: 0 } }, //97
            { pct: 0.868, color: { r: 0x40, g: 0xff, b: 0 } }, //99
            { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
    } else if (type === StatType.Arena) {
        percentColors = [
            { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
            { pct: 0.551, color: { r: 0xff, g: 0x40, b: 0 } }, //30
            { pct: 0.633, color: { r: 0xff, g: 0x80, b: 0 } }, //50
            { pct: 0.711, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
            { pct: 0.752, color: { r: 0xff, g: 0xff, b: 0 } }, //80
            { pct: 0.812, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
            { pct: 0.853, color: { r: 0x80, g: 0xff, b: 0 } }, //95
            { pct: 0.923, color: { r: 0x40, g: 0xff, b: 0 } }, //99
            { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
    } else if (rarity === 5) {
        if (type === StatType.Shuttle) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.436, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.476, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.581, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.667, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.783, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.836, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.934, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Voyage) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.459, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.604, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.684, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.728, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.786, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.836, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.922, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Event) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.661, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.728, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.777, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.81, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.841, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.876, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.943, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Collection) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.551, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.633, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.711, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.752, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.812, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.853, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.923, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        }
    } else if (rarity === 4) {
        if (type === StatType.Shuttle) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.712, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.784, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.846, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.872, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.886, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.928, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.953, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Voyage) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.418, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.598, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.691, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.738, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.786, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.842, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.9, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Event) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.658, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.728, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.79, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.824, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.861, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.901, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.957, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        } else if (type === StatType.Collection) {
            percentColors = [
                { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
                { pct: 0.518, color: { r: 0xff, g: 0x40, b: 0 } }, //30
                { pct: 0.557, color: { r: 0xff, g: 0x80, b: 0 } }, //50
                { pct: 0.667, color: { r: 0xff, g: 0xc0, b: 0 } },  //70
                { pct: 0.715, color: { r: 0xff, g: 0xff, b: 0 } }, //80
                { pct: 0.782, color: { r: 0xc0, g: 0xff, b: 0 } }, //90
                { pct: 0.818, color: { r: 0x80, g: 0xff, b: 0 } }, //95
                { pct: 0.893, color: { r: 0x40, g: 0xff, b: 0 } }, //99
                { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } } ]; //100
        }
    }

    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
    // or output as hex if preferred
};

export function rarityClass(rarity: number) {
    if (rarity === 5) {
        return "legendary"
    } else if (rarity === 4) {
        return "superrare"
    } else if (rarity === 3) {
        return "rare"
    } else if (rarity === 2) {
        return "uncommon"
    } else if (rarity === 1) {
        return "common"
    }
}

const isSkill = (segment: string) => {
    return segment === "cmd" || segment === "dip" || segment === "eng" || segment === "sci" || segment === "sec" || segment === "med"
}
const isArenaBoost = (segment: string) => {
    return segment === "attack" || segment === "att" || segment === "evasion" || segment === "ev" || segment === "acc" || segment === "accuracy"
}
const matchesFilter = (input: string, searchString: string) => {
    if (!input) {
        return false;
    }
    return input.toLowerCase().indexOf(searchString) >= 0;
}
const matchesFilterT = (input: string, searchString: string) => {
    if (searchString === "rom" || searchString === "human" || searchString === "male" || hiddenTraits.find(x=>x==searchString)) {
        return input === searchString;
    }
    return input.toLowerCase().indexOf(searchString) >= 0;
}
const hiddenTraits = [ "tosbridge", "tos", "tas", "dsc", "ent", "tng", "voy", "pic", "ds9", "low", "lds", "vst", "original"]

const checkValue = (stat:any, value:any, negated: boolean) => {
    //console.log(stat, value, negated);
    if (!value.substring) return (stat == +value) ? !negated : negated;
    if (value[0]==":") value=value.substring(1);
    switch (value[0]) {
        case "<":
            if (value[1] == "=") return (stat <= +value.substring(2)) ? !negated : negated;
            return (stat < +value.substring(1)) ? !negated : negated;
        case ">":
            if (value[1] == "=") return (stat >= +value.substring(2)) ? !negated : negated; 
            return (stat > +value.substring(1)) ? !negated : negated;
        default: return (stat == +value) ? !negated : negated;
    }
}

export function showThisCrew(crew: NamerCrew, filters: any[], filterType: string, arena?: boolean): boolean {

    for (const filter of filters) {
        let tempResult = true;
        for (const condition of filter.conditionArray) {
            let keyword = condition.keyword.toLowerCase();
            let value = condition.value.toLowerCase();

            if (keyword === "symbol" || keyword === "sym") {
                tempResult = crew.symbol.toString() === value ? !condition.negated : condition.negated;
            } else if (keyword === "collection" || keyword === "c") {
                tempResult = crew.collections.find(x=>matchesFilter(x,value)) ? !condition.negated : condition.negated;
            } else if (keyword === "primary" || keyword === "p") {
                tempResult = (crew.skills[0] === value) ? !condition.negated : condition.negated;
            } else if (keyword === "secondary" || keyword === "s") {
                tempResult = (crew.skills.length > 1 && crew.skills[1] === value) ? !condition.negated : condition.negated;
            } else if (keyword === "tertiary" || keyword === "t") {
                tempResult = (crew.skills.length > 2 && crew.skills[2] === value) ? !condition.negated : condition.negated;
            } else if (keyword === "crnow") {
                let vstr = value.substring(1);
                if (vstr === "crlater") {
                    tempResult = checkValue(crew.crNow??(crew.portal?100:0), value.substring(0,1)+(crew.crLater??100), condition.negated);
                } else
                tempResult = checkValue(crew.crNow??(crew.portal?100:0), value, condition.negated);
            } else if (keyword === "crlater") {
                let vstr = value.substring(1);
                if (vstr === "crnow") {
                    tempResult = checkValue(crew.crLater??100, value.substring(0,1)+(crew.crNow??(crew.portal?100:0)), condition.negated);
                } else
                tempResult = checkValue(crew.crLater??100, value, condition.negated);
            } else if (arena) {
                if (keyword === "limit" || keyword === "l") {
                    tempResult = checkValue(crew.ship_action.limit, value, condition.negated);
                } else if (keyword === "ability" || keyword === "a") {
                    tempResult = (crew.ship_action.ability ? !condition.negated : condition.negated) && checkValue(crew.aAbAmount, value, condition.negated);
                } else if (keyword === "trigger" || keyword === "tr") {
                    const [icon, alt, title] = getShipTriggerNameAndIcon(crew.ship_action.ability.condition);
                    tempResult = title.toLowerCase().includes(value) ? !condition.negated : condition.negated
                } else if (keyword === "penalty" || keyword === "p") {
                    if (!crew.ship_action.penalty) tempResult = condition.negated;
                    else tempResult = checkValue(crew.ship_action.penalty.amount, value, condition.negated);
                } else if (keyword === "cycle" || keyword === "cy") {
                    tempResult = checkValue(crew.ship_action.cycle, value, condition.negated);
                } else if (keyword === "uptime" || keyword === "up") {
                    tempResult = checkValue(crew.ship_action.uptime, value, condition.negated); 
                } else if (keyword === "init" || keyword === "i") {
                    tempResult = checkValue(crew.ship_action.initial_cooldown, value, condition.negated);
                } else if (keyword === "cooldown" || keyword === "cd") {
                    tempResult = checkValue(crew.ship_action.cooldown, value, condition.negated);
                } else if (keyword === "duration" || keyword === "d") {
                    tempResult = checkValue(crew.ship_action.duration, value, condition.negated);
                } else if (keyword === "att") {
                    tempResult = (crew.ship_action.bonus_type == 0 ? !condition.negated : condition.negated) && checkValue(crew.aBoost, value, condition.negated);
                } else if (keyword === "acc") {
                    tempResult = (crew.ship_action.bonus_type == 2 ? !condition.negated : condition.negated) && checkValue(crew.aBoost, value, condition.negated);
                } else if (keyword === "ev") {
                    tempResult = (crew.ship_action.bonus_type == 1 ? !condition.negated : condition.negated) && checkValue(crew.aBoost, value, condition.negated);
                }
            }
            if (!tempResult) {
                return false;
            }
        }
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            if (isSkill(segment.text)) {
                tempResult = crew.skills.some(s => s === segment.text) ? !segment.negated : segment.negated;
            } else if (segment.text == "portal") {
                tempResult = crew.portal == true ? !segment.negated : segment.negated;
            } else if (segment.text == "honor") {
                tempResult = crew.obtained == "Honor Hall" ? !segment.negated : segment.negated;
            } else if (segment.text == "mega") {
                tempResult = crew.obtained == "Mega" ? !segment.negated : segment.negated;
            } else if (segment.text == "giveaway") {
                tempResult = crew.obtained == "Giveaway" ? !segment.negated : segment.negated;
            } else if (segment.text == "voyage") {
                tempResult = crew.obtained == "Voyage" ? !segment.negated : segment.negated;
            } else if (segment.text == "fusion") {
                tempResult = crew.obtained == "Fusion" ? !segment.negated : segment.negated;
            } else if (segment.text == "campaign") {
                tempResult = crew.obtained == "Campaign" ? !segment.negated : segment.negated;
            } else if (segment.text == "collection") {
                tempResult = (crew.obtained == "Collection" || crew.symbol == "janeway_davenport_crew") ? !segment.negated : segment.negated;
            } else if (arena && (isArenaBoost(segment.text))) {
                if (segment.text === "attack" || segment.text === "att") {
                    tempResult = crew.ship_action.bonus_type == 0;
                } else if (segment.text === "evasion" || segment.text === "ev") {
                    tempResult = crew.ship_action.bonus_type == 1;
                } else if (segment.text === "accuracy" || segment.text === "acc") {
                    tempResult = crew.ship_action.bonus_type == 2;
                }
            } else if (hiddenTraits.includes(segment.text)) {
                const searchText = segment.text == "lds" ? "low" : segment.text;
                tempResult = crew.hiddenTraits.includes(searchText) ? !segment.negated : segment.negated;
            } else {
                let skillCombos = [];
                if (segment.text.length > 3 && (segment.text[3]==="|" || segment.text[3]==="/") && isSkill(segment.text.substring(0, 3))) {
                    let tokens = segment.text.split(/[/|]+/);
                    if ((tokens.length === 2 || tokens.length === 3) && tokens.every(t => t === "" || isSkill(t))) {
                        skillCombos.push(tokens[0]+"/"+tokens[1]);
                        if (segment.text[3]==="|") {
                            skillCombos.push(tokens[1]+"/"+tokens[0]);
                        }
                        if (segment.text.length > 7) {
                            if (segment.text[7]==="|") {
                                skillCombos.forEach(function(part, index, theArray) {
                                    skillCombos.push(tokens[2]+"/"+theArray[index]);
                                    theArray[index] += "/" + tokens[2];                                    
                                });
                                skillCombos.push(tokens[0]+"/"+tokens[2]+"/"+tokens[1]);
                                skillCombos.push(tokens[1]+"/"+tokens[2]+"/"+tokens[0]);
                            } else {
                                skillCombos.forEach(function(part, index, theArray) {
                                    theArray[index] += "/" + segment.text.substring(8);
                                });
                            }
                        }
                    }
                }
                tempResult =
                    (arena ? crew.aSearch.some(t => matchesFilter(t, segment.text)) : false) ||
                    (matchesFilter(crew.name, segment.text) ||
                    (crew.nicknames && crew.nicknames.some(t => matchesFilter(t.cleverThing, segment.text))) ||
                    matchesFilter(crew.triplet, segment.text) ||
                    skillCombos.some(t => matchesFilter(crew.triplet, t)) ||
                    crew.collections.some(c => matchesFilter(c, segment.text)) ||
                    crew.traits.some(t => matchesFilterT(t, segment.text)) ||
                    crew.traitsNamed.some(t => matchesFilterT(t, segment.text)) ||
                    crew.hiddenTraits.some(t => matchesFilterT(t, segment.text))) ? !segment.negated : segment.negated;
            }
            if (!tempResult) {
                return false;
            }
        }
    }
    return true;
}

export function showThisQuipment(quipment: Quipment, filters: any[], filterType: string, arena?: boolean): boolean {

    for (const filter of filters) {
        let tempResult = true;
        for (const condition of filter.conditionArray) {
            let keyword = condition.keyword.toLowerCase();
            let value = condition.value.toLowerCase();

            if (keyword === "symbol" || keyword === "sym") {
                tempResult = quipment.symbol.toString() === value ? !condition.negated : condition.negated;
            } else if (keyword === "maxrarity" || keyword === "maxr") {
                checkValue(quipment.max_rarity_requirement, value, condition.negated);
            }

            if (!tempResult) {
                return false;
            }
        }
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            if (isSkill(segment.text)) {
                tempResult = quipment.rolls.get(segment.text) > 0 ? !segment.negated : segment.negated;
            } else {
                tempResult =
                    (matchesFilter(quipment.name, segment.text) ||
                    matchesFilter(quipment.flavor, segment.text) ||
                    (quipment.traits_requirement && quipment.traits_requirement.some(t => matchesFilterT(t, segment.text)))) ? !segment.negated : segment.negated;
            }
            if (!tempResult) {
                return false;
            }
        }
    }
    return true;
}

export function showThisShip(ship: ShipInfo, filters: any[], filterType: string, arena?: boolean): boolean {
    for (const filter of filters) {
        let tempResult = true;
        for (const condition of filter.conditionArray) {
            let keyword = condition.keyword.toLowerCase();
            let value = condition.value.toLowerCase();

            if (keyword === "symbol" || keyword === "sym") {
                tempResult = ship.symbol.toString() === value ? !condition.negated : condition.negated;
            }

            for (const action of ship.actions) {
                if (keyword === "limit" || keyword === "l") {
                    tempResult = checkValue(action.limit, value, condition.negated);
                } else if (keyword === "ability" || keyword === "a") {
                    tempResult = (action.ability ? !condition.negated : condition.negated) && checkValue(action.ability.amount, value, condition.negated);
                } else if (keyword === "trigger" || keyword === "tr") {
                    const [icon, alt, title] = getShipTriggerNameAndIcon(action.ability.condition);
                    tempResult = title.toLowerCase().includes(value) ? !condition.negated : condition.negated
                } else if (keyword === "penalty" || keyword === "p") {
                    if (!action.penalty) tempResult = condition.negated;
                    else tempResult = checkValue(action.penalty.amount, value, condition.negated);
                } else if (keyword === "cycle" || keyword === "cy") {
                    tempResult = checkValue(action.cycle, value, condition.negated);
                } else if (keyword === "uptime" || keyword === "up") {
                    tempResult = checkValue(action.uptime, value, condition.negated); 
                } else if (keyword === "init" || keyword === "i") {
                    tempResult = checkValue(action.initial_cooldown, value, condition.negated);
                } else if (keyword === "cooldown" || keyword === "cd") {
                    tempResult = checkValue(action.cooldown, value, condition.negated);
                } else if (keyword === "duration" || keyword === "d") {
                    tempResult = checkValue(action.duration, value, condition.negated);
                } 
                // else if (keyword === "att") {
                //     tempResult = (action.bonus_type == 0 ? !condition.negated : condition.negated) && checkValue(action.bonus_amount, value, condition.negated);
                // } else if (keyword === "acc") {
                //     tempResult = (action.bonus_type == 2 ? !condition.negated : condition.negated) && checkValue(action.bonus_amount, value, condition.negated);
                // } else if (keyword === "ev") {
                //     tempResult = (action.bonus_type == 1 ? !condition.negated : condition.negated) && checkValue(action.bonus_amount, value, condition.negated);
                // }
                if (tempResult) break;
            }
            if (keyword === "att") {
                tempResult = checkValue(ship.cAttack, value, condition.negated);
            } else if (keyword === "acc") {
                tempResult = checkValue(ship.cAccuracy, value, condition.negated);
            } else if (keyword === "ev") {
                tempResult = checkValue(ship.cEvasion, value, condition.negated);
            }
            if (!tempResult) {
                return false;
            }
        }
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            // if (isSkill(segment.text)) {
            //     tempResult = ship.skills.some(s => s === segment.text) ? !segment.negated : segment.negated;
            // } else if (segment.text == "portal") {
            //     tempResult = ship.portal == true ? !segment.negated : segment.negated;
            // } else if (segment.text == "honor") {
            //     tempResult = crew.obtained == "Honor Hall" ? !segment.negated : segment.negated;
            // } else if (segment.text == "voyage") {
            //     tempResult = crew.obtained == "Voyage" ? !segment.negated : segment.negated;
            // } else if (segment.text == "fusion") {
            //     tempResult = crew.obtained == "Fusion" ? !segment.negated : segment.negated;
            // } else if (segment.text == "campaign") {
            //     tempResult = crew.obtained == "Campaign" ? !segment.negated : segment.negated;
            // } else if (segment.text == "collection") {
            //     tempResult = (crew.obtained == "Collection" || crew.symbol == "janeway_davenport_crew") ? !segment.negated : segment.negated;
            // } else if (arena && (isArenaBoost(segment.text))) {
                // if (segment.text === "attack" || segment.text === "att") {
                //     for (const action of ship.actions) {
                //         tempResult = action.bonus_type == 0;
                //         if (tempResult) break;
                //     }
                // } else if (segment.text === "evasion" || segment.text === "ev") {
                //     for (const action of ship.actions) {
                //         tempResult = action.bonus_type == 1;
                //         if (tempResult) break;
                //     }
                // } else if (segment.text === "accuracy" || segment.text === "acc") {
                //     for (const action of ship.actions) {
                //         tempResult = action.bonus_type == 2;
                //         if (tempResult) break;
                //     }
                // }
            // } else if (hiddenTraits.includes(segment.text)) {
            //     const searchText = segment.text == "lds" ? "low" : segment.text;
            //     tempResult = crew.hiddenTraits.includes(searchText) ? !segment.negated : segment.negated;
            // } else {
            //     let skillCombos = [];
            //     if (segment.text.length > 3 && (segment.text[3]==="|" || segment.text[3]==="/") && isSkill(segment.text.substring(0, 3))) {
            //         let tokens = segment.text.split(/[/|]+/);
            //         if ((tokens.length === 2 || tokens.length === 3) && tokens.every(t => t === "" || isSkill(t))) {
            //             skillCombos.push(tokens[0]+"/"+tokens[1]);
            //             if (segment.text[3]==="|") {
            //                 skillCombos.push(tokens[1]+"/"+tokens[0]);
            //             }
            //             if (segment.text.length > 7) {
            //                 if (segment.text[7]==="|") {
            //                     skillCombos.forEach(function(part, index, theArray) {
            //                         skillCombos.push(tokens[2]+"/"+theArray[index]);
            //                         theArray[index] += "/" + tokens[2];                                    
            //                     });
            //                     skillCombos.push(tokens[0]+"/"+tokens[2]+"/"+tokens[1]);
            //                     skillCombos.push(tokens[1]+"/"+tokens[2]+"/"+tokens[0]);
            //                 } else {
            //                     skillCombos.forEach(function(part, index, theArray) {
            //                         theArray[index] += "/" + segment.text.substring(8);
            //                     });
            //                 }
            //             }
            //         }
            //     }
                tempResult =
                    ship.aSearch.some(t => matchesFilter(t, segment.text)) ||
                    (matchesFilter(ship.name, segment.text) ||
                    // matchesFilter(crew.triplet, segment.text) ||
                    // skillCombos.some(t => matchesFilter(crew.triplet, t)) ||
                    // crew.collections.some(c => matchesFilter(c, segment.text)) ||
                    ship.traits.some(t => matchesFilterT(t, segment.text)) ||
                    ship.traits_hidden.some(t => matchesFilterT(t, segment.text))) ? !segment.negated : segment.negated;
            //}
            if (!tempResult) {
                return false;
            }
        }
    }
    return true;
}

export function showThisEvent(event: FullEventInfo, filters: any[], filterType: string, arena?: boolean): boolean {

    for (const filter of filters) {
        let tempResult = true;
        for (const segment of filter.textSegments) {
            segment.text = segment.text.toLowerCase();
            
            if (segment.text == "skirmish" || segment.text == "galaxy" || segment.text == "faction" || segment.text == "expedition") {
                tempResult = matchesFilter(event.type, segment.text) ? !segment.negated : segment.negated;
            } else {
                let f = hiddenTraits.find(x=>x==segment.text);
                tempResult =
                    ((!f ? matchesFilter(event.name, segment.text) : matchesFilterT(event.name, segment.text)) ||
                    matchesFilter(event.type, segment.text) ||
                    (event.factions ?? []).some(t => !f ? matchesFilter(t, segment.text) : matchesFilterT(t, segment.text)) ||
                    (event.featured ?? []).some(t => !f ? matchesFilter(t.name, segment.text) : matchesFilterT(t.name, segment.text)) ||
                    (event.variants?? []).some(t => matchesFilterT(t, segment.text)) ||
                    (event.traits??[]).some(t => matchesFilterT(t, segment.text)) ||
                    (event.named_traits??[]).some(t => matchesFilterT(t, segment.text))) ? !segment.negated : segment.negated;   
            }
        }
        if (!tempResult) {
            return false;
        }
    }
    return true;
}


export function getShipBoostElement(amount:number, type: number, hidePlusSign?: boolean) {
    if (!amount || amount == 0) {
        return;
    }
    if (type == undefined) {
        type = 0;
    }
    if (type == 3) {
        return amount + " to ShieldRegen" 
    }
    let icon = "", alt = "", title = "";
    let color, style;
    //console.log(amount, type, hidePlusSign);
    switch (type) {
        case 0: icon = "sb_torpedo_attack.png"; color = "rgb(208,68,68)"; alt = "ATT"; style = { width:"20px", height:"20px", marginLeft:"1px"}; title = "Attack"; break;
        case 1: icon = "sb_evasion.png"; color = "rgb(126,177,232)"; alt = "EV"; style = { width:"20px", height:"12px", marginTop:"1px" }; title = "Evasion"; break;
        case 2: icon = "sb_accuracy.png"; color = "rgb(236,165,11)"; alt = "ACC"; style = { width:"20px", height:"20px", marginLeft:"1px" }; title = "Accuracy"; break;
        default: color = "pink"; break;
    }
    return <div className="sbBoost" style={{backgroundColor:color}}>
        <span><b>{(amount>0&&!hidePlusSign)?"+":""}{amount}</b></span><img src={getAssetURL(icon)} alt={alt} style={style} title={title}/>
    </div>
}


export function getShipTriggerNameAndIcon(type: number) {
    let icon = "", alt = "", title = "";
    switch (type) {
        case 1: alt = "Position"; icon = "sb_advantage.png"; title = "Position"; break;
        case 2: alt = "Cloak"; icon = "sb_cloak.png"; title = "Cloak"; break;
        case 4: alt = "Boarding"; icon = "sb_boarding.png"; title = "Boarding"; break;
        case 8: alt = "Blocked Boarding"; icon = "icon_shipability_blockedBoarding.png"; title = "Blocked Boarding"; break;
        case 16: alt = "Disrupt Shield"; icon = "icon_shipability_disruptShield.png"; title = "Disrupt Shield"; break;
    }
    return [icon, alt, title];
}

export function getAbilityNameAndIcon(crew: NamerCrew, amount: number) {
    let desc = "", icon = "";
    switch (crew.ship_action.ability.type) {
        case 0:
            let type = "";
            switch (crew.ship_action.bonus_type) {
                case 0: type = "Attack"; icon = "sb_torpedo_attack"; break;
                case 1: type = "Evasion"; icon = "sb_evasion"; break;
                case 2: type = "Accuracy"; icon = "sb_accuracy"; break;
            }
            let total = amount + crew.ship_action.bonus_amount + crew.rarity - 1;
            desc = "Increases " + type + " boost to +" + total; break;
        case 1: desc = "Immediately deals " + amount + "% Damage"; icon = "sb_torpedo_attack"; break;
        case 2: desc = "Immediately repairs Hull by " + amount + "%"; icon = "sb_hull_repair"; break;
        case 3: desc = "Immediately repairs Shields by " + amount + "%"; icon = "sb_shield_regeneration"; break;
        case 4: desc = "+" + amount + " to Crit Rating"; icon = "sb_crit_chance_increase"; break;
        case 5: desc = "+" + amount + " to Crit Bonus"; icon = "sb_crit_bonus_increase"; break;
        case 6: desc = "+" + amount + " to Shield Regeneration"; icon = "sb_shield_regeneration"; break;
        case 7: desc = "+" + amount + "% to Attack Speed"; icon = "sb_phaser_torpedo_attack"; break;
        case 8: desc = "Increases boarding damage by " + amount + "%"; icon = "sb_boarding"; break;
        case 10: desc = "Speeds up cooldown timers by " + amount + " seconds"; icon = "icon_shipability_overcharge"; break;
        case 9: desc = "Resets enemy cooldown timers"; icon = "icon_shipability_emp"; break;
        case 11: desc = "Reduce Incoming Hull damage by " + amount + "%"; icon = "icon_shipability_electroplating"; break;
        case 12: desc = amount + "% of incoming damage also taken by attacker"; icon = "icon_shipability_reflectordish"; break;
    }
    return [icon != "" ? icon+".png" : icon, desc];
  }

  export function getShipAbilityNameAndIcon(action: ShipAction, amount: number) {
    let desc = "", icon = "";
    switch (action.ability.type) {
        case 0:
            let type = "";
            switch (action.bonus_type) {
                case 0: type = "Attack"; icon = "sb_torpedo_attack"; break;
                case 1: type = "Evasion"; icon = "sb_evasion"; break;
                case 2: type = "Accuracy"; icon = "sb_accuracy"; break;
            }
            let total = amount + action.bonus_amount;
            desc = "Increases " + type + " boost to +" + total; break;
        case 1: desc = "Immediately deals " + amount + "% Damage"; icon = "sb_torpedo_attack"; break;
        case 2: desc = "Immediately repairs Hull by " + amount + "%"; icon = "sb_hull_repair"; break;
        case 3: desc = "Immediately repairs Shields by " + amount + "%"; icon = "sb_shield_regeneration"; break;
        case 4: desc = "+" + amount + " to Crit Rating"; icon = "sb_crit_chance_increase"; break;
        case 5: desc = "+" + amount + " to Crit Bonus"; icon = "sb_crit_bonus_increase"; break;
        case 6: desc = "+" + amount + " to Shield Regeneration"; icon = "sb_shield_regeneration"; break;
        case 7: desc = "+" + amount + "% to Attack Speed"; icon = "sb_phaser_torpedo_attack"; break;
        case 8: desc = "Increases boarding damage by " + amount + "%"; icon = "sb_boarding"; break;
        case 10: desc = "Speeds up cooldown timers by " + amount + " seconds"; icon = "icon_shipability_overcharge"; break;
        case 9: desc = "Resets enemy cooldown timers"; icon = "icon_shipability_emp"; break;
        case 11: desc = "Reduce Incoming Hull damage by " + amount + "%"; icon = "icon_shipability_electroplating"; break;
        case 12: desc = amount + "% of incoming damage also taken by attacker"; icon = "icon_shipability_reflectordish"; break;
    }
    return [icon, desc];
  }

export function getChargePhases(crew: NamerCrew) {
    if (!crew.ship_action.charge_phases) {
        return;
    }
    const getAbilityTypeName = (type: number) => {
        switch (type) {
            case 0: return "Attack"; 
            case 1: return "Evasion";
            case 2: return "Accuracy";
        }
        return "unknown";
    }
    
    let init = 0;
    return <React.Fragment>
        {crew.ship_action.charge_phases.map((x, idx) => {
            init += x.charge_time;
            let bonus = x.bonus_amount - crew.ship_action.bonus_amount;
            let cd = x.cooldown - crew.ship_action.cooldown;
            let dur = x.duration - crew.ship_action.duration;
            let penalty = x.penalty_amount - (crew.ship_action.penalty ? crew.ship_action.penalty.amount : 0);
            const [_, desc] = getAbilityNameAndIcon(crew, x.ability_amount);
            return <div key={idx} style={{marginTop:"5px", marginBottom:"5px"}}>
                <span>
                    <span className={"sbCharge"+crew.ship_action.bonus_type}>{idx+1}</span>
                    After {init}s
                    {x.bonus_amount ? (", " + (bonus>0?"+":"") + bonus + " " + getAbilityTypeName(crew.ship_action.bonus_type)) : ""}
                    {x.ability_amount ? (", " + desc) : ""}
                    {x.cooldown ? (", " + (cd>0?"+":"") + cd + " cooldown") : ""}
                    {x.duration ? (", " + (dur>0?"+":"") + dur + " duration") : ""}
                    {x.penalty_amount ? (", " + (penalty>0?"+":"") + penalty + " " + getAbilityTypeName(crew.ship_action.penalty.type)) : ""}
                </span>
                <br/>
            </div>})}
    </React.Fragment>
  }

  export function getShipChargePhases(ship_action: ShipAction) {
    if (!ship_action.charge_phases) {
        return;
    }
    const getAbilityTypeName = (type: number) => {
        switch (type) {
            case 0: return "Attack"; 
            case 1: return "Evasion";
            case 2: return "Accuracy";
        }
        return "unknown";
    }
    
    let init = 0;
    return <React.Fragment>
        {ship_action.charge_phases.map((x, idx) => {
            init += x.charge_time;
            let bonus = x.bonus_amount - ship_action.bonus_amount;
            let cd = x.cooldown - ship_action.cooldown;
            let dur = x.duration - ship_action.duration;
            let penalty = x.penalty_amount - (ship_action.penalty ? ship_action.penalty.amount : 0);
            const [_, desc] = getShipAbilityNameAndIcon(ship_action, x.ability_amount);
            return <div key={idx} style={{marginTop:"5px", marginBottom:"5px"}}>
                <span>
                    <span className={"sbCharge"+ship_action.bonus_type}>{idx+1}</span>
                    After {init}s
                    {x.bonus_amount ? (", " + (bonus>0?"+":"") + bonus + " " + getAbilityTypeName(ship_action.bonus_type)) : ""}
                    {x.ability_amount ? (", " + desc) : ""}
                    {x.cooldown ? (", " + (cd>0?"+":"") + cd + " cooldown") : ""}
                    {x.duration ? (", " + (dur>0?"+":"") + dur + " duration") : ""}
                    {x.penalty_amount ? (", " + (penalty>0?"+":"") + penalty + " " + getAbilityTypeName(ship_action.penalty.type)) : ""}
                </span>
                <br/>
            </div>})}
    </React.Fragment>
  }

  export function unityRichTextToHtml(input: string) {        
    let bCount = (input.match(/<b>/gi) || []).length;
    let iCount = (input.match(/<i>/gi) || []).length;
    let uCount = (input.match(/<u>/gi) || []).length;
    let cCount = (input.match(/<#.+?>/gi) || []).length;
    let sCount = (input.match(/<size.+?>/gi) || []).length;
    
    let cTags = (input.match(/</gi) || []).length;
    if (cTags != bCount + iCount + uCount + cCount + sCount) return input;

    for (let i=0; i<bCount; i++) input += "</b>";
    for (let i=0; i<iCount; i++) input += "</i>";
    for (let i=0; i<uCount; i++) input += "</u>";
    for (let i=0; i<cCount; i++) input += "</span>";
    input = input.replace(/<size\=(.*?)>/gi, function(i, match) {
        return ``;
    });
    input = input.replace(/<#(.*?)>/gi, function(i, match) {
        return `<span style="color:#` + match + `">`;
    });
    return input;
  }

  export function unityRichTextToCleanText(input: string) {
    input = input.replace(/<(.*?)>/gi, function(i, match) {
        return ``;
    });
    return input;
  }