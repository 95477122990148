import React from 'react';
import ReactGA from 'react-ga4';
import { Button, Dimmer, Divider, GridColumn, GridRow, Icon, RatingIcon, Segment, Statistic } from 'semantic-ui-react';
import { Link, navigate } from "@reach/router";
import ImageFadeIn from "react-image-fade-in";
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import '../App.css';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { getColorForPercentage, logEvent, rarityClass } from '../utils/utils'
import { CRMention, FeaturedCrewSkillInfo, getTierConfig } from '../services/NamerStats';
import ReactPlayer from 'react-player';
import ReadMore from './ReadMore';
import { url } from 'inspector';
import { getAssetURL } from '../App';
import AdSense from 'react-adsense';

export enum FeaturedCrewSkill {
    Command = "cmd",
    Diplomacy = "dip",
    Security = "sec",
    Science = "sci",
    Engineering = "eng",
    Medicine = "med"
}

export enum StatType {
    Undefined = -1,

    Voyage = 0,
    Gauntlet = 1,
    Shuttle = 2,
    Event = 3,
    Arena = 4,
    Collection = 5,
    Flavor = 6,
    Tier = 7,
    Variants = 8,
    Quipment = 9
}

export const FeaturedCrew = (props: {
    index: number;
    symbol: string;
	name: string;
    rarity: number;
    image: string;
    portrait: string;
    background: string;
    primary: FeaturedCrewSkillInfo;
    secondary?: FeaturedCrewSkillInfo;
    tertiary?: FeaturedCrewSkillInfo;
    rawPrimary: FeaturedCrewSkillInfo;
    rawSecondary?: FeaturedCrewSkillInfo;
    rawTertiary?: FeaturedCrewSkillInfo;
    leftImage?: boolean;
    noteDate?: Date;
    noteAuthor?: string;
    note?: string;
    traits: string[];
    collections?: string[];
    tier?: number;
    event?: number;
    eventR?: number;
    shuttle?: number;
    shuttleR?: number;
    gauntlet?: number;
    gauntletR?: number;
    voyage?: number;
    voyageR?: number;
    arena?: number;
    arenaR?: number;
    coll?: number;
    collR?: number;
    bbLink?: string;
    video?: string; 
    audio?: string;
    crewPage?: boolean;
    crMentions?: CRMention[]
    crNow?: number;
    crLater?: number;
    date?: Date;
    obtained?: string;
    portal?: boolean;
    nicknames?: {
        cleverThing: string
        creator?: string
    }[]
    showAd: boolean;
    ftm_name?: string;
    ftm_date?: Date;
    handleClick?: (StatType) => void
}) => {
    const featuredDesktopAds = [ '7960598806', '4258933614', '4276342966'];
    const featuredMobileAds = [ '6125000897', '6693525267', '2730229525']
    const isOnlyMobile = isMobile && !isTablet;
    const statTypeFromStringMap = new Map<StatType, string>([ [StatType.Undefined, "undefined"],
        [StatType.Tier, "tier"], [StatType.Voyage, "voyage"], [StatType.Gauntlet, "gauntlet"], [StatType.Event, "event"],
        [StatType.Shuttle, "shuttle"], [StatType.Collection, "collection"], [StatType.Arena, "arena"], [StatType.Flavor, "flavor"]
    ]);
    const [dimmerActive, setDimmerActive] = React.useState<boolean>(false);
    const imgRef = React.useRef(null);
    const onUpdate = React.useCallback(({ x, y, scale }) => {
        const { current: img } = imgRef;

        if (img) {
            const value = make3dTransformValue({ x, y, scale });
            img.style.setProperty("transform", value);
        }
    }, []);
    
    const voyageStyle = {
        color: getColorForPercentage((props.voyage ?? 0)/10, StatType.Voyage, props.rarity)
    }
    const gauntletStyle = {
        color: getColorForPercentage((props.gauntlet ?? 0)/10, StatType.Gauntlet, props.rarity)
    }
    const eventStyle = {
        color: getColorForPercentage((props.event ?? 0)/10, StatType.Event, props.rarity)
    }
    const shuttleStyle = {
        color: getColorForPercentage((props.shuttle ?? 0)/10, StatType.Shuttle, props.rarity)
    }
    const arenaStyle = {
        color: getColorForPercentage((props.arena ?? 0)/10, StatType.Arena, props.rarity)
    }
    const collStyle = {
        color: getColorForPercentage((props.coll ?? 0)/10, StatType.Collection, props.rarity)
    }
    const [visibleAd, setVisibleAd] = React.useState<boolean>(false);
    const [showVideo, setShowVideo] = React.useState<boolean>(props.video && props.video !== "");
    const [imgLoaded, setImgLoaded] = React.useState<boolean>(false);
    const [primary, setPrimary] = React.useState<FeaturedCrewSkillInfo>(props.primary);
    const [secondary, setSecondary] = React.useState<FeaturedCrewSkillInfo>((props.secondary && props.secondary.base !== 0) ? props.secondary : undefined);
    const [tertiary, setTertiary] = React.useState<FeaturedCrewSkillInfo>((props.tertiary && props.tertiary.base !== 0) ? props.tertiary : undefined);
    const [boosted, setBoosted] = React.useState<boolean>(true);
    const [showAudio, setShowAudio] = React.useState<boolean>(false);

    React.useEffect(() => {
        setShowVideo(props.video && props.video !== "");
    }, [props.video]);

    React.useEffect(()=>{
        if (props.showAd) {
            // if (props.index == 0) {
                setVisibleAd(true);
            // } else {
            //     setTimeout(() => {
            //         setVisibleAd(true);
            //     }, Math.min(props.index * 500, 1000));
            // }
        }
    }, []);

    React.useEffect(() => {
        setShowAudio(false);
        setTimeout(() => {
            setShowAudio(props.audio && props.audio !== "");
        }, 100);
    }, [props.audio]);

    let audio = props.audio; //https://docs.google.com/uc?export=open&id=1C0v6Q1SnI5fkbADpSzM-M5KNaOxVpF3d
    if (audio && audio != "") {
        const index = audio.indexOf("&id=");
        if (index > 0) {
            audio = audio.substring(index+4);
        }
    }

    let obtained = props.obtained;
    if (obtained === "N/A") {
        obtained = "";
    }

    let imageStyle = {};
    if (!imgLoaded) {
        imageStyle = { display: "none" };
    }

    const switchBoosted = () => {
        setPrimary(!boosted ? props.primary : props.rawPrimary);
        if (secondary) setSecondary(!boosted ? props.secondary : props.rawSecondary);
        if (tertiary) setTertiary(!boosted ? props.tertiary : props.rawTertiary);
        setBoosted(!boosted);
    }

    const getSkillIconWidth = (skill) => {
        switch (skill) {
            case FeaturedCrewSkill.Command: return "28.3px";
            case FeaturedCrewSkill.Diplomacy: return "39.71px";
            case FeaturedCrewSkill.Security: return "46.54px";
            case FeaturedCrewSkill.Science: return "30.03px";
            case FeaturedCrewSkill.Engineering: return "34.16px";
            case FeaturedCrewSkill.Medicine: return "38.63px";
        }
    }

    const getNicknames = () => {
        if (!props.nicknames) {
            return ""
        } else {
            return <span>; a.k.a. {props.nicknames.map((x,idx)=><span key={idx}>{x.cleverThing}{!x.creator?"":<span> (coined by <i>{x.creator}</i>)</span>}{(idx != props.nicknames.length-1)?" or ":""}</span>)}</span>
        }
    }

    const getPortal = () => {
        if (props.portal == true) {
            return "";
        }
        return <span>; Not in the Portal.</span>
    }

    const getObtainedInfo = () => {
        if (obtained === "Event") {
            obtained = "as event reward";
        } else if (obtained === "Mega") {
            obtained = "as a recurring mega card";
        } else if (obtained === "Honor Hall") {
            obtained = "in the honor hall";
        } else if (obtained === "Giveaway") {
            obtained = "as a giveaway";
        } else if (obtained === "Pack") {
            obtained = "in a pack";
        } else if (obtained === "Campaign") {
            obtained = "in a campaign";
        } else if (obtained === "Collection") {
            obtained = "as collection reward";
        } else if (obtained === "Fusion") {
            obtained = "as a fusion card";
        } else if (obtained === "Gauntlet") {
            obtained = "as a gauntlet card";
        } else if (obtained === "Post-Launch") {
            obtained = " post-launch";
        } else if (obtained === "Voyage") {
            obtained = " as a voyage exclusive card";
        } else if (obtained === "Missions") {
            obtained = "as an away mission reward";
        } else if (obtained === "Launch") {
            obtained = "at game launch";
        } else if (obtained === "WebStore") {
            obtained = " as a cash-only card"
        } else if (obtained !== "" && obtained != undefined) {
            obtained = "(" + obtained + ")"; 
        }

        if (!props.date) {
            return <span>Not yet introduced</span>
        }
        let date = new Date(props.date);
        if (date.getFullYear() === 1) {
            return <span>Not yet introduced</span>;
        }
        return <span>{isOnlyMobile?"":"Introduced on "}{date.toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})} {obtained??""}{getNicknames()}{getPortal()}</span>
    }

    const getFTMData = () => {
        if (!props.ftm_name == undefined || props.ftm_name == "" || props.ftm_date == undefined) {
            return;
        }
        let date = new Date(props.ftm_date);
        if (date.getFullYear() === 1) {
            return;
        }
        return <span>FTM by <b>{props.ftm_name}</b> on {date.toLocaleDateString("en-US", {month: "short", day: "2-digit", year: "numeric"})}</span>
    }

    const getTier = () => {
        if (!props.tier) return "?";
        if (!props.portal && props.obtained == "WebStore") return "$"
        return props.tier
    }

    const handleNavigate = (stat: StatType, url: string) => {
        ReactGA.event({category:"CrewScorecard", action:statTypeFromStringMap.get(stat)});
        // if (props.handleClick) {
        //     props.handleClick(stat);
        // } else {
            navigate(url);
        //}
    }

    const getCrewName = () => {
        if (!props.handleClick) {
            return (<Link className={rarityClass(props.rarity)}
                to={"/crew/"+props.symbol}
                onClick={() => logEvent("CrewLink", "featured", props.symbol)}>
                {props.name}
            </Link>)
        } else {
            return props.name
        }
    }

    let crWarning = false;
    if (props.crNow || props.crLater) {
        crWarning = true;
    }

    const getCRWarning = () => {
        if (!props.crLater) {
            return;
        }
        if (!props.crNow) {
            return "Best retrieval chance will be only " + props.crLater + "% when this crew enters the portal"
        } else if (props.crNow === props.crLater) {
            return "Best retrieval chance is only " + props.crNow + "%"
        } else if (props.crMentions) {
            const getNameLinks = () => {
                return props.crMentions.map((mention, idx) => {
                    if (mention.rarity < 4) {
                        return <span key={idx}>{mention.name}{idx < props.crMentions.length - 1? ", ": ""}</span>
                    }
                    return <span key={idx}>
                        <span className={rarityClass(mention.rarity)}><a className={rarityClass(mention.rarity)} href={"/crew/"+mention.symbol}>{mention.name}</a></span>{idx < props.crMentions.length - 1? ", ": ""}
                    </span>
                });
            }
            return <span>
                Best retrieval chance will decrease from {props.crNow}% to {props.crLater}% when {getNameLinks()} {props.crMentions.length > 1 ? "enter" : "enters"} the portal 
            </span>
        }
    }

    return (
        <React.Fragment key={props.symbol}>
            <GridRow centered className={isMobileOnly ? "" : "imgBg"} style={isMobileOnly?{}:{
                    backgroundImage: "url(" + getAssetURL(props.background ?? (props.primary.skill+"_room.png"), true) + ")",
                }}>
                {isOnlyMobile && <div className="feature-mobile">
                    <h1 className={(rarityClass(props.rarity)) + " feature-note-header-mobile center"}>
                        {getCrewName()}
                    </h1>
                    <div className="feature-avatar-stats-mobile">
                        <div className="feature-avatar-mobile" style={{  position: 'relative', display: 'inline-block' }}
                            onClick={()=>setDimmerActive(true)}>
                            <div className="shadow" style={{ position: 'relative', display: 'inline-block' }}>
                                <img className="gauntlet-image" src={getAssetURL(props.portrait)} height={200} alt={props.name}/>
                            </div>
                        </div>
                        <Dimmer active={dimmerActive} onSwipe={()=>setDimmerActive(false)} onClick={() => setDimmerActive(false)} page>
                            <QuickPinchZoom onUpdate={onUpdate}>
                                <img ref={imgRef} src={getAssetURL(props.image)} height={window.innerHeight} alt={props.name}/>
                            </QuickPinchZoom>
                        </Dimmer>
                        <div className="experimental-skills-mobile">
                            <div className="experimental-skill-tier" onClick={()=>handleNavigate(StatType.Tier,"/ratings/overall?loc="+props.symbol)}>
                                <span>Tier {getTier()}</span>
                            </div>
                            <div className="experimental-skillicon-container">
                                <img className="experimental-skillicon-mobile" src={getAssetURL("icon_"+primary.skill+".png")} alt="PRI"/>
                            </div>
                            <div className="experimental-skill-mobile">
                                {primary.base}<small className="experimental-skill-prof-mobile">({primary.min}-{primary.max})</small>
                            </div>
                            {secondary && <div className="experimental-skillicon-container">
                                <img className="experimental-skillicon-mobile" src={getAssetURL("icon_"+secondary.skill+".png")} alt="SEC"/>
                            </div>}
                            {secondary && <div className="experimental-skill-mobile">
                                {secondary.base}<small className="experimental-skill-prof-mobile">({secondary.min}-{secondary.max})</small>
                            </div>}
                            {tertiary && <div className="experimental-skillicon-container">
                                <img className="experimental-skillicon-mobile" src={getAssetURL("icon_"+tertiary.skill+".png")} alt="TER"/>
                            </div>}
                            {tertiary && <div className="experimental-skill-mobile">
                                {tertiary.base}<small className="experimental-skill-prof-mobile">({tertiary.min}-{tertiary.max})</small>
                            </div>}
                            <div className="boostSwitch-mobile" onClick={() => switchBoosted()}>
                                <Icon className={"arrow alternate circle "+ (boosted ? "up" : "down") + " outline"}/> {boosted ? "boosted" : "unboosted"}
                            </div>
                        </div>
                    </div>
                    {props.video && <React.Fragment>                        
                        {!showVideo && <Button className="feature-vidbtn" compact icon='play' content='Play video review' onClick={() => setShowVideo(true)}/>}
                        {showVideo && <Button className="feature-vidbtn" compact icon='file alternate outline' content='Read text review' onClick={() => setShowVideo(false)}/>}
                    </React.Fragment>}
                    {showAudio && <audio controls className="audioPlayerM" title={props.name}>
                        <source src={"https://www.googleapis.com/drive/v3/files/"+audio+"?alt=media&key=AIzaSyCBdxOYaH-lPZWm8uY-j-2amq3nav7FEGI"} type="audio/mp4" />
                        <p>Your browser does not support HTML5 audio to play this crew review :(</p>
                    </audio>}
                    {props.note && !showVideo && <h1 className="feature-note-text-mobile" style={{ fontFamily: 'Roboto Slab' }}>
                        {/* {!props.handleClick && props.note.split("\n\n").map((p, idx) => <p key={idx}>{p.split("\n").map((line, lineIndex)=><React.Fragment key={lineIndex}>{line}<br/></React.Fragment>)}</p>)}
                        {props.handleClick && <ReadMore text={props.note}/>} */}
                        <ReadMore text={props.note} author={props.noteAuthor} date={props.noteDate}/>
                    </h1>}
                    {showVideo && <ReactPlayer
                        className="playerMobile"
                        url={props.video}
                        playing={true}
                        controls={true}
                        muted={true}
                    />}
                    <div className="feature-stats">
                        {crWarning && <span className="feature-traits"><Icon className="feature-icon" name='exclamation circle' verticalalign='top'/>{getCRWarning()}</span>}
                        <span className="feature-traits"><Icon className="feature-icont" size="large" name='heartbeat' verticalalign='top'/>
                            {props.traits.map((x,idx)=><span key={"trait"+idx}>
                                <Link className="blueLink" to={"/ratings/overall?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "trait", props.symbol)}>{x}</Link>
                                {idx===props.traits.length-1?"":", "}
                            </span>)}
                        </span>
                        {props.collections && <span className="feature-coll"><Icon name='box' className="feature-icon" verticalalign='top'/>
                            {props.collections.map((x,idx)=><span key={"coll"+idx}>
                                <Link className="blueLink" to={"/ratings/overall?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "collection", props.symbol)}>{x}</Link>
                                {idx===props.collections.length-1?"":", "}
                            </span>)}
                        </span>}
                        {props.ftm_date && <span className="feature-coll"><Icon name='flag checkered' className="feature-icon"  verticalalign='top'/>{getFTMData()}</span>}
                        <span className="feature-coll"><Icon name='calendar alternate outline' className="feature-icon"  verticalalign='top'/>{getObtainedInfo()}</span>
                    </div>
                    <Segment>
                        <Statistic.Group inverted size="small">
                            {/* <Statistic className="feature-tier-mobile" onClick={()=>handleNavigate(StatType.Tier,"/ratings/overall?loc="+props.symbol)}>
                                <Statistic.Label>Tier</Statistic.Label>
                                <Statistic.Value>{props.tier === undefined ? "?" : props.tier}</Statistic.Value>
                            </Statistic>
                            <Divider/> */}
                            {props.voyage && <Statistic className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Voyage,"/ratings/voyage?loc="+props.symbol)}>
                                <Statistic.Label>Voyage</Statistic.Label>
                                <Statistic.Value style={voyageStyle}>{props.voyage}</Statistic.Value>
                                <Statistic.Label>#{props.voyageR}</Statistic.Label>
                            </Statistic>}
                            <Divider/>
                            {props.gauntlet && <Statistic className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Gauntlet, "/ratings/gauntlet?loc="+props.symbol)}>
                                <Statistic.Label>Gauntlet</Statistic.Label>
                                <Statistic.Value style={gauntletStyle}>{props.gauntlet}</Statistic.Value>
                                <Statistic.Label>#{props.gauntletR}</Statistic.Label>
                            </Statistic>}
                            <Divider/>
                            {props.event && <Statistic className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Event, "/ratings/event?loc="+props.symbol)}>
                                <Statistic.Label>Event</Statistic.Label>
                                <Statistic.Value style={eventStyle}>{props.event}</Statistic.Value>
                                <Statistic.Label>#{props.eventR}</Statistic.Label>
                            </Statistic>}
                            <Divider/>
                            {props.shuttle && <Statistic className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Shuttle, "/ratings/shuttle?loc="+props.symbol)}>
                                <Statistic.Label>Shuttle</Statistic.Label>
                                <Statistic.Value style={shuttleStyle}>{props.shuttle}</Statistic.Value>
                                <Statistic.Label>#{props.shuttleR}</Statistic.Label>
                            </Statistic>}
                            <Divider/>
                            {props.coll && <Statistic size="small" className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Collection, "/ratings/collection?loc="+props.symbol)}>
                                <Statistic.Label>Collection</Statistic.Label>
                                <Statistic.Value style={collStyle}>{props.coll}</Statistic.Value>
                                <Statistic.Label>#{props.collR}</Statistic.Label>
                            </Statistic>}
                            <Divider/>
                            {props.arena && <Statistic className="feature-score-mobile" onClick={()=>handleNavigate(StatType.Arena, "/ratings/arena?loc="+props.symbol)}>
                                <Statistic.Label>Ship</Statistic.Label>
                                <Statistic.Value style={arenaStyle}>{props.arena}</Statistic.Value>
                                <Statistic.Label>#{props.arenaR}</Statistic.Label>
                            </Statistic>}
                        </Statistic.Group>
                    </Segment>
                </div>}
                {!isOnlyMobile && props.leftImage && <GridColumn centered={"true"} className="feature-image-col">
                    <ImageFadeIn style={imageStyle} opacityTransition={1} src={getAssetURL(props.image)} onLoad={()=>setImgLoaded(true)}/>
                </GridColumn>}
                {!isOnlyMobile && <GridColumn verticalAlign="middle" textAlign="center" className="feature-note-col">
                    <h1 className={(rarityClass(props.rarity)) + " feature-note-header"}>
                        {getCrewName()}
                    </h1>
                    <span style={{height:"38px"}} className="experimental-skills">
                        <span className="experimental-skill">
                            <span style={{display:"inline-block",width:getSkillIconWidth(primary.skill)}}>
                                <img className="experimental-skillicon" src={getAssetURL("icon_"+primary.skill+".png")} alt="PRI"/>
                            </span>
                            <span className="experimental-skillcore">{primary.base}</span>
                            <span className="experimental-skillprof">({primary.min}-{primary.max})</span>
                        </span>
                        {secondary && <span className="experimental-skill">
                            <span style={{display:"inline-block",width:getSkillIconWidth(secondary.skill)}}>
                                <img className="experimental-skillicon" src={getAssetURL("icon_"+secondary.skill+".png")} alt="SEC"/>
                            </span>
                            <span className="experimental-skillcore">{secondary.base}</span>
                            <span className="experimental-skillprof">({secondary.min}-{secondary.max})</span>
                        </span>}
                        {tertiary && <span className="experimental-skill">
                            <span style={{display:"inline-block",width:getSkillIconWidth(tertiary.skill)}}>
                                <img className="experimental-skillicon" src={getAssetURL("icon_"+tertiary.skill+".png")} alt="TER"/>
                            </span>
                            <span className="experimental-skillcore">{tertiary.base}</span>
                            <span className="experimental-skillprof">({tertiary.min}-{tertiary.max})</span>
                        </span>}
                    </span>
                    <div style={{height:"1em"}} className="boostSwitch" onClick={() => switchBoosted()}>
                        <Icon className={"arrow alternate circle "+ (boosted ? "up" : "down") + " outline"}/> {boosted ? "boosted" : "unboosted"}
                    </div>
                    <br/>
                    {props.video && <React.Fragment>                        
                        {!showVideo && <Button className="feature-vidbtn" compact icon='play' content='Play video review' onClick={() => setShowVideo(true)}/>}
                        {showVideo && <Button className="feature-vidbtn" compact icon='file alternate outline' content='Read text review' onClick={() => setShowVideo(false)}/>}
                    </React.Fragment>}
                    {showAudio && <audio controls className="audioPlayer" title={props.name}>
                        <source src={"https://www.googleapis.com/drive/v3/files/"+audio+"?alt=media&key=AIzaSyCBdxOYaH-lPZWm8uY-j-2amq3nav7FEGI"} type="audio/mp4" />
                        <p>Your browser does not support HTML5 audio to play this crew review :(</p>
                    </audio>}
                    {props.note && !showVideo && <h1 className="feature-note-text" style={{ fontFamily: 'Roboto Slab' }}>
                        {/*{!props.handleClick && props.note.split("\n\n").map((p, idx) => <p key={idx}>{p.split("\n").map((line, lineIndex)=><React.Fragment key={lineIndex}>{line}<br/></React.Fragment>)}</p>)}
                        */}
                        <ReadMore text={props.note} author={props.noteAuthor} date={props.noteDate}/>
                    </h1>}
                    {showVideo && <ReactPlayer
                        url={props.video}
                        playing={true}
                        controls={true}
                        muted={true}
                    />}
                    <div className="feature-stats">
                        {crWarning && <span className="feature-traits"><Icon className="feature-icon" name='exclamation circle' size="large" verticalalign='top'/>{getCRWarning()}</span>}
                        <span className="feature-traits"><Icon className="feature-icont" size="large" name='heartbeat' verticalalign='top'/>
                            {props.traits.map((x,idx)=><span key={"trait"+idx}>
                                <Link className="blueLink" to={"/ratings/overall?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "trait", props.symbol)}>{x}</Link>
                                {idx===props.traits.length-1?"":", "}
                            </span>)}
                        </span>
                        {props.collections && <span className="feature-coll"><Icon name='box' className="feature-icon" verticalalign='top'/>
                            {props.collections.map((x,idx)=><span key={"coll"+idx}>
                                <Link className="blueLink" to={"/ratings/overall?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "collection", props.symbol)}>{x}</Link>
                                {idx===props.collections.length-1?"":", "}
                            </span>)}
                        </span>}
                        {props.ftm_date && <span className="feature-coll"><Icon name='flag checkered' className="feature-icon"  verticalalign='top'/>{getFTMData()}</span>}
                        <span className="feature-coll"><Icon name='calendar alternate outline' className="feature-icon"  verticalalign='top'/>{getObtainedInfo()}</span>
                    </div>
                    <Segment>
                        <Statistic.Group inverted >
                            <Statistic className="feature-tier" onClick={()=>handleNavigate(StatType.Tier,"/ratings/overall?loc="+props.symbol)}>
                                <Statistic.Label>Tier</Statistic.Label>
                                <Statistic.Value>{getTier()}</Statistic.Value>
                            </Statistic>
                            {props.voyage && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Voyage, "/ratings/voyage?loc="+props.symbol)}>
                                <Statistic.Label>Voyage</Statistic.Label>
                                <Statistic.Value style={voyageStyle}>{props.voyage}</Statistic.Value>
                                <Statistic.Label>#{props.voyageR}</Statistic.Label>
                            </Statistic>}
                            {props.gauntlet && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Gauntlet, "/ratings/gauntlet?loc="+props.symbol)}>
                                <Statistic.Label>Gauntlet</Statistic.Label>
                                <Statistic.Value style={gauntletStyle}>{props.gauntlet}</Statistic.Value>
                                <Statistic.Label>#{props.gauntletR}</Statistic.Label>
                            </Statistic>}
                            {props.event && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Event, "/ratings/event?loc="+props.symbol)}>
                                <Statistic.Label>Event</Statistic.Label>
                                <Statistic.Value style={eventStyle}>{props.event}</Statistic.Value>
                                <Statistic.Label>#{props.eventR}</Statistic.Label>
                            </Statistic>}
                            {props.shuttle && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Shuttle, "/ratings/shuttle?loc="+props.symbol)}>
                                <Statistic.Label>Shuttle</Statistic.Label>
                                <Statistic.Value style={shuttleStyle}>{props.shuttle}</Statistic.Value>
                                <Statistic.Label>#{props.shuttleR}</Statistic.Label>
                            </Statistic>}
                            {props.coll && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Collection, "/ratings/collection?loc="+props.symbol)}>
                                <Statistic.Label>Collection</Statistic.Label>
                                <Statistic.Value style={collStyle}>{props.coll}</Statistic.Value>
                                <Statistic.Label>#{props.collR}</Statistic.Label>
                            </Statistic>}
                            {props.arena && <Statistic size="small" className="feature-score" onClick={()=>handleNavigate(StatType.Arena, "/ratings/arena?loc="+props.symbol)}>
                                <Statistic.Label>Ship</Statistic.Label>
                                <Statistic.Value style={arenaStyle}>{props.arena}</Statistic.Value>
                                <Statistic.Label>#{props.arenaR}</Statistic.Label>
                            </Statistic>}
                        </Statistic.Group>
                    </Segment>
                </GridColumn>}
                {!isOnlyMobile && !props.leftImage && <GridColumn className="feature-image-col">
                    <ImageFadeIn style={imageStyle} opacityTransition={1} src={getAssetURL(props.image)} onLoad={()=>setImgLoaded(true)}/>
                </GridColumn>}
            </GridRow>
            {visibleAd && <GridRow centered className="center">
                <div className="adslot">
                    {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
                    {isMobileOnly && <AdSense.Google
                        className="ad"
                        client='ca-pub-5995456634193629'
                        slot={featuredMobileAds[Math.floor(props.index/3)%featuredMobileAds.length]}
                        style={{ display: 'block' }}
                        format='auto'
                        responsive='true'
                    />}
                    {!isMobileOnly && <AdSense.Google
                        className="ad adfixed"
                        client='ca-pub-5995456634193629'
                        slot={featuredDesktopAds[Math.floor(props.index/3)%featuredDesktopAds.length]}
                        style={{ display: 'inline-block', width: '970px', height: '250px', border: '1px solid #444444'}}
                        format='fixed'
                    />}
                </div>
            </GridRow>}
            {!props.crewPage && <Divider/>}
        </React.Fragment>)
}