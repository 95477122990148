import '../assets/fomantic/dist/semantic.css';
import { Container, Table, Image, Segment, Grid } from 'semantic-ui-react';
import { Link } from '@reach/router';
import React from 'react';
import {Helmet} from 'react-helmet';

import '../App.css';
import { NamerCrew, getCrew, EventInfo, getEventInfo, FullEventInfo, getFullEventInfo, BBCrew } from '../services/NamerStats';
import { SearchableTable, ITableConfigRow } from './SearchableTable';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';
import EventStats from './CrewStats/EventStats';
import { logEvent, rarityClass, showThisCrew, showThisEvent } from '../utils/utils';
import { getAssetURL } from '../App';

export const getFeatured = (c: BBCrew, idx: number, mobile?: boolean) => {
    return <div className={mobile?"variantsM":"variants"} key={"feat"+idx}>
        <Link className={rarityClass(c.rarity)} to={"/crew/"+c.symbol} 
            onClick={()=>logEvent("CrewLink", "variantList", c.symbol)}>
            <img className="var-portrait" src={getAssetURL(c.portrait)} alt={c.name}/>
        </Link>                                           
        <div className="var-details">
            <div className="var-details-c">
                <span className={rarityClass(c.rarity)+" var-name"}>
                    <Link className={rarityClass(c.rarity)} to={"/crew/"+c.symbol} 
                        onClick={()=>logEvent("CrewLink", "variantList", c.symbol)}>{c.name}</Link>
                </span>
                <br/>
                <span className="var-tier">Tier <b>{c.tier}</b> <span style={{marginLeft: '5px', fontSize: "small", color: "silver"}}>{c.type}</span></span>
            </div>
        </div>
    </div>   
}

export const traitIcon = (trait:string) => {
    if (trait === "physician") {
        return getAssetURL("items_keystones_doctor.png")
    } else {
        return getAssetURL("items_keystones_"+trait+".png")
    }
}

export const getEventTraitHeader = (letter, nonbonus, trait, mobile) => {

    let style = {};
    if (mobile && letter) {
        style = {marginRight:"5px"}
    }
    return <React.Fragment>
        {!letter && !nonbonus && <Image avatar className="er-traitimg" src={traitIcon(trait)} />}
        {letter && <Image><span className="center" style={style}>{letter}</span></Image>}
    </React.Fragment>
}

export const EventList = (props: {
    navigation: () => void;
}) => {
    const isOnlyMobile = isMobile && !isTablet;
    const [eventList, setEventList] = React.useState<FullEventInfo[]|undefined>(undefined);
    const [year, setYear] = React.useState<number>(0);

    React.useEffect(() => {
        props.navigation();
        if (!eventList) {
            getFullEventInfo().then((data) => setEventList(data));
        }
    }, [])

    React.useEffect(() => {
        getFullEventInfo().then((data) => setEventList(year == 0 ? data : data.filter(x=>new Date(x.start).getFullYear() == year)));
    }, [year]);

    if (eventList == undefined) {
        return <Container><div className="center ui active loader"/></Container>;
    }

    const eventTypes = new Map([
        ["Faction", "F"],
        ["Galaxy", "G"],
        ["Skirmish", "S"],
        ["Expedition", "E"],
        ["Faction/Galaxy", "F/G" ],
        ["Galaxy/Faction", "G/F" ],
        ["Faction/Skirmish", "F/S" ],
        ["Skirmish/Faction", "S/F" ],
        ["Faction/Expedition", "F/E" ],
        ["Expedition/Faction", "E/F" ],
        ["Galaxy/Skirmish", "G/S" ],
        ["Skirmish/Galaxy", "S/G" ],
        ["Skirmish/Expedition", "S/E" ],
        ["Expedition/Skirmish", "E/S" ],
        ["Expedition/Faction/Galaxy", "E/F/G" ],
        ["Faction/Galaxy/Expedition", "F/G/E" ]
    ])

    const tableConfig: ITableConfigRow[] = [
        { width: 2, column: 'name', title: 'Title', class: 'row-header', secondaryColumn: '-start' },
        { width: 1, column: 'start', title: 'Date', class: 'row-header' },
        { width: 1, column: 'type', title: 'Type', class: 'row-header', secondaryColumn: '-start'},
        { width: 6, column: '-featured.length', title: 'Featured', secondaryColumn: 'start', class: 'row-header'},
        { width: 2, column: '-rewards.length', title: 'Rewards', secondaryColumn: 'start', class: 'row-header'},
        { width: 1, column: 'traits', title: 'Traits', class: 'row-header', secondaryColumn: '-start'},
        { width: 1, column: 'variants', title: 'Variants', class: 'row-header', secondaryColumn: '-start'},
    ]

    const descriptionLabel = (event: FullEventInfo) => {
        return <span style={{fontSize:"small"}}>
            {event.named_factions?.join(", ")}
            {/* {event.traits?.map((x,idx)=><span key={"trait"+idx}>
                <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(event.named_traits[idx])} onClick={()=>logEvent("SearchType", "trait", x)}>{x}</Link>
                {", "}
            </span>)}
            {event.variants.map((x,idx)=><span key={"var"+idx}>
                <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "variant", x)}>{x}</Link>
                {idx===event.variants.length-1?"":", "}
            </span>)} */}
        </span>
    }

    const getRowBackground = (event: FullEventInfo) => {
        return "linear-gradient(rgba(22,22,22,0.8), rgba(22,22,22,0.8)), url(" + getAssetURL(event.motd, true) + ")"
    }

  const renderTableRow = (event: FullEventInfo, idx: number) => {
    return (
        <React.Fragment>
            <Table.Cell>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateRows: 'auto auto',
                        gridTemplateAreas: `'stats'
                                            'description'`,
                        gridGap: '1px',
                        textAlign: 'center'
                    }}
                >
                    <div style={{ gridArea: 'stats' }}>
                        <Link className="blueLink tng"
                            to={"/event/"+event.id}
                            style={{ fontSize: '2em' }}
                            onClick={()=>logEvent("EventLink", "event", event.id.toString())}>
                            {event.name}
                        </Link>
                    </div>
                    <div className="eventTraits" style={{ gridArea: 'description', fontSize:"small" }}>{event.named_factions?.map((x,idx)=>
                        <div key={"fac"+idx}>
                            {x}<br/>
                        </div>
                    )}</div>
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {new Date(event.start).toLocaleDateString("en-US", {timeZone: 'UTC', month: "short", day: "2-digit", year: "numeric"})}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {event.type.split("/").map((x,idx)=><div key={"typ"+idx}>{x}<br/></div>)}
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Grid stackable padded>
                    {event.featured?.map((x,idx)=>getFeatured(x,idx))}
                </Grid>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <Grid stackable padded>
                    {event.rewards?.map((x,idx)=>getFeatured(x,idx))}
                </Grid>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                <div className="eventTraits">
                    {event.traits?.map((trait,idx)=>{
                        let letter: string | undefined;
                        let nonbonus: boolean = false
                        if (trait === "tosbridge" || trait === "tos") {
                            letter = "TOS"
                        } else if (trait === "dsc") {
                            letter = "DSC"
                        } else if (trait === "ent") {
                            letter = "ENT"
                        } else if (trait === "tng") {
                            letter = "TNG"
                        } else if (trait === "voy") {
                            letter = "VOY"
                        } else if (trait === "pic") {
                            letter = "PIC"
                        } else if (trait === "ds9") {
                            letter = "DS9"
                        } else if (trait === "low") {
                            letter = "LDS"
                        } else if (trait === "vst") {
                            letter = "VST"
                        } else if (trait === "tas") {
                            letter = "TAS"
                        } else if (trait === "snw") {
                            letter = "SNW"
                        } else if (trait === "original") {
                            letter = "Original"
                        }
                        if (trait.length==7 && !isNaN(trait.substring(3, 6) as any)) {
                            return <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)} key={"t"+idx}>{trait}</Link>
                        }
                        if (letter) {
                            return <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)}  key={"t"+idx}>{getEventTraitHeader(letter, nonbonus, trait, isOnlyMobile)}</Link>
                        }
                        return <span key={"t"+idx}>{getEventTraitHeader(letter, nonbonus, trait, isOnlyMobile)}<Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(trait)} onClick={()=>logEvent("SearchType", "trait", trait)}>{event.named_traits[idx]}</Link></span>;
                    })}
                </div>
            </Table.Cell>
            <Table.Cell style={{ textAlign: 'center' }}>
                {event.variants?.map((x,idx)=>
                <div key={"v"+idx}>
                    <Link className="blueLink" to={"/ratings/shuttle?search="+encodeURIComponent(x)} onClick={()=>logEvent("SearchType", "variant", x)}>{x}</Link>
                    {idx===event.variants.length-1?"":<br/>}
                </div>)}
            </Table.Cell>
        </React.Fragment>
    );
  }

  const title = "Events - The Big Book of Behold Advice";
  const link = "https://www.bigbook.app/events";
  const description = "List of events in Star Trek Timelines, event information, details and leaderboard";

  return (
    <div>
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={link} />
            <meta name="description" content={description}/>
            <meta property="og:url" content={link}/>
            <meta property="og:description" content={description}/>
            <meta name="twitter:url" content={link}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
        </Helmet>
        <div className="rarityfilter">
            <span className={"tierFilter"+(year==0?" tierFilterSelected":"")} onClick={()=>setYear(0)}>All</span>|
            <span className={"tierFilter"+(year==2024?" tierFilterSelected":"")} onClick={()=>setYear(2024)}>2024</span>
            <span className={"tierFilter"+(year==2023?" tierFilterSelected":"")} onClick={()=>setYear(2023)}>2023</span>
            <span className={"tierFilter"+(year==2022?" tierFilterSelected":"")} onClick={()=>setYear(2022)}>2022</span>
            <span className={"tierFilter"+(year==2021?" tierFilterSelected":"")} onClick={()=>setYear(2021)}>2021</span>
            <span className={"tierFilter"+(year==2020?" tierFilterSelected":"")} onClick={()=>setYear(2020)}>2020</span>
            <span className={"tierFilter"+(year==2019?" tierFilterSelected":"")} onClick={()=>setYear(2019)}>2019</span>
            <span className={"tierFilter"+(year==2018?" tierFilterSelected":"")} onClick={()=>setYear(2018)}>2018</span>
            <span className={"tierFilter"+(year==2017?" tierFilterSelected":"")} onClick={()=>setYear(2017)}>2017</span>
            <span className={"tierFilter"+(year==2016?" tierFilterSelected":"")} onClick={()=>setYear(2016)}>2016</span>
        </div>
        <SearchableTable
            id="event_list"
            data={eventList}
            config={tableConfig}
            renderTableRow={(event, idx) => renderTableRow(event, idx)}
            getRowBackground={(event) => getRowBackground(event)}
            filterRow={showThisEvent}
            showFilterOptions={false}
            showSearchExplanation={false}
            hideRarityFilter={true}
        />
        <Footer/>
    </div>
  );
}

export default EventList;
