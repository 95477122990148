import { Grid, Image } from 'semantic-ui-react';
import { navigate, Link } from "@reach/router";
import ReactGA from "react-ga4";
import '../App.css';
import { isDesktop, isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import React from 'react';
import AdSense from 'react-adsense';

export const Footer = (props:{
  showFleet?: boolean,
  loaded?: () => void
}) => {
    let mobileOrientation = useMobileOrientation();
    // const amznads = [
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B0B69XJQFD&asins=B0B69XJQFD&linkId=fed10791222136a933100181dab6f82b&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B0B69YZP7G&asins=B0B69YZP7G&linkId=b408476ed88bec479e0b327c24856148&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=0762463643&asins=0762463643&linkId=86743052ecf0343bce6f4d5c6c75f269&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=0762459336&asins=0762459336&linkId=f9190005d009d4f05c1e675175f070b4&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B08MNSJLC5&asins=B08MNSJLC5&linkId=30805bbc39a2a343a27f24e766176b3c&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B09S6Q6LSF&asins=B09S6Q6LSF&linkId=1527ba5635a75485ae8cc29ae142ce9f&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B09RQ8VK25&asins=B09RQ8VK25&linkId=5999658789796dd9e5490a58873edc36&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B09RQB1HKD&asins=B09RQB1HKD&linkId=b5a459a0ed6b281d9bb7ce6667629a77&show_border=true&link_opens_in_new_window=true",
    //   "//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=nonamenamer-20&language=en_US&marketplace=amazon&region=US&placement=B092KV8L6K&asins=B092KV8L6K&linkId=8a909c810985254161d46bec3b36def8&show_border=true&link_opens_in_new_window=true"
    // ];

    const [visibleAd, setVisibleAd] = React.useState<boolean>(false);
    // const [isUSA, setIsUsa] = React.useState<boolean>(false);
    React.useEffect(() => {
      if (props.loaded) props.loaded();
      // fetch("https://ipapi.co/json/").then((data) => data.json().then((json) => {
      //   setIsUsa(json.country == "US");
      // }))

      setTimeout(() => {
        setVisibleAd(true);
      }, 500);
    }, []);

    const discordURL = "https://bit.ly/trek-time";
    const forumURL = "https://forum.wickedrealmgames.com/stt/discussion/18921/the-big-book-of-behold-advice-v2-0";
    const privacyURL = "https://www.bigbook.app/privacy.html";
    return (
    <div className="center" style={{paddingTop:"100px", paddingBottom:"50px", opacity: 0.8, minWidth:"300px"}}>
        <div className="footer" style={{minWidth:"300px"}}>
          {!props.showFleet && !isDesktop && <Image className="footerLogo" src="/officiallogo.webp" onClick={() => navigate("/about") }/>}
          <br />
          <span>
            <Link className="footerLink" to={"/about"}>About</Link>{' | '}
            {/* <Link className="footerLink" to={"/faq"}>FAQ</Link>{' | '} */}
            <Link className="footerLink" to={"/help"}>How to search</Link>{' | '}
            <a target="_blank" className="footerLink" href={forumURL} rel="noreferrer" onClick={() => {ReactGA.event({category:"ExternalLink",action:"forum",label:forumURL})}}>Forum</a>{' | '}
            <a target="_blank" className="footerLink" href={privacyURL} rel="noreferrer" onClick={() => {ReactGA.event({category:"ExternalLink",action:"privacy",label:forumURL})}}>Privacy</a>
          </span>
          <br/>
          <a target="_blank" className="footer-discordlink" href={discordURL} rel="noreferrer" onClick={() => {ReactGA.event({category:"ExternalLink",action:"discord",label:discordURL})}}>Join us on <img className="footer-img" height="15px" src="/discord.webp"/>Trek Time</a>
          {/* <br/>
          {isUSA && <Grid padded columns={(isMobileOnly&&mobileOrientation.isPortrait)?3:9}>
            {amznads.map((ad,idx)=><Grid.Column key={idx}>
              <iframe sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
                style={{width:"120px",height:"240px", border:"0px", margin:"0 0 0 0", overflow:"hidden", background:"#222222"}}
                src={ad}></iframe>
            </Grid.Column>)}
          </Grid>}*/}
          <br/>
          {visibleAd && <div className="adslot" style={{minWidth:'300px'}}>
              {(window as any).adsbygoogle?.loaded && <span className='adlabel'>Advertisement</span>}
              <AdSense.Google
                className={"footerad ad"+(isMobileOnly?"":" adwide")}
                client='ca-pub-5995456634193629'
                slot='9827747655'
                style={isMobileOnly?{ display: 'block', minWidth: '300px' } : { display: 'block', minWidth: '970px' }}
                format='autorelaxed'
                responsive='true'
              />
            </div>}
          {/* <a href='https://ko-fi.com/U7U15UENO' target='_blank'>
            <img height='36' className='kofi'
              src='https://storage.ko-fi.com/cdn/kofi5.png?v=3'
              alt='Buy us a coffee at ko-fi.com'/>
          </a> */}
        </div>
      </div>
    )
}