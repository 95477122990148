import '../assets/fomantic/dist/semantic.css';
import { Grid, Container, Header } from 'semantic-ui-react';
import { navigate } from "@reach/router";
import React from 'react';

import '../App.css';
import { EventLeaderboard, getLeaderboard } from '../services/NamerStats';
import { isMobile, isTablet } from 'react-device-detect';
import { Footer } from './Footer';

export const About = (props:{
    id?: number;
    navigation: () => void;
}) => {

    React.useEffect(() => {
        props.navigation();
    }, [])

    return (<div>
        <div className='credits'>
            <br/>
            <h1>The Big Book of Behold Advice</h1>
            <br/>
            
            <p>
                <h2>Author</h2>
                <span>Automaton_2000</span>
            </p>

            <p>
                <h2>Ratings and systems</h2>
                <span>NoNameNamer</span>
            </p>

            <p>
                <h2>Contributors</h2>
                <span>Captain Idol</span>
                <span>StarsAndGarters</span>
                <span>BigMcLargeHuge</span>
                <span>Jenos Idanian</span>
                <span>Nifty Mittens</span>
                <span>Frank</span>
            </p>
        </div>
        <br/>
        <Footer/>
    </div>);
}

export default About;