import React from 'react';
import { Table, Input, Pagination, Dropdown, Popup, Icon, Button } from 'semantic-ui-react';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import ReactGA from "react-ga4";
import AdSense from 'react-adsense';

import {navigate, Link } from "@reach/router";

import { IConfigSortData, IResultSortDataBy, sortDataBy } from '../utils/datasort';
import { useStateWithStorage } from '../utils/storage';
import { logEvent } from '../utils/utils';

const SearchString = require('search-string');

const filterTypeOptions = [
    { key : '0', value : 'Exact', text : 'Exact match only' },
    { key : '1', value : 'Whole word', text : 'Whole word only' },
    { key : '2', value : 'Any match', text : 'Match any text' }
];

const pagingOptions = [
	{ key: '0', value: '10', text: '10' },
	{ key: '1', value: '25', text: '25' },
	{ key: '2', value: '50', text: '50' },
	{ key: '3', value: '100', text: '100' }
];

export interface ITableConfigRow {
	width: number;
	column: string;
	secondaryColumn?: string;
	title: string | JSX.Element;
	pseudocolumns?: string[];
	class?: string;
}

type SearchableTableProps = {
	id?: string;
	data: any[];
	explanation?: React.ReactNode;
	config: ITableConfigRow[];
	renderTableRow: (row: any, idx?: number) => JSX.Element;
	renderExpandedRow?: (row: any, idx?: number) => JSX.Element;
	filterRow: (crew: any, filter: any, filterType?: string) => boolean;
	getRowBackground?: (row: any) => string;
	hideSearch?: boolean;
    showFilterOptions: boolean;
	showSearchExplanation?: boolean;
	showTierFilter?: boolean;
	hideRarityFilter?: boolean;
	defaultPerPage?: number;
	clearSearch?: boolean;
	hideFooter?: boolean;
	querySuggestions?: boolean;
	fullSearchBar?: boolean;
	mobileWidth?: boolean;
};

export const SearchableTable = (props: SearchableTableProps) => {

	let mobileOrientation = useMobileOrientation();
    let isOnlyMobile = isMobile && !isTablet && mobileOrientation.isPortrait;
	
	const tableId = props.id ? props.id : '';

	// Ignore stored searchFilter if search parameter found
	let defaultSearch = '', useAndStoreDefault = false;
	let urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('search')) {
		defaultSearch = urlParams.get('search') ?? "";
		useAndStoreDefault = true;
	}

	const [pagination_rows, setPaginationRows] = useStateWithStorage(tableId+'paginationRows', props.defaultPerPage ?? pagingOptions[0].value);

	let locPage: number | undefined;
	let locIndex: number | undefined;
	if (urlParams.has("loc")) {
		let symbol = urlParams.get("loc");
		let index = props.data.findIndex(x=>x.symbol == symbol);
		if (index >= 0) {
			locPage = Math.ceil((index+1) / pagination_rows) ;
			let totalPages = Math.ceil(props.data.length / pagination_rows);
			if (locPage > totalPages) locPage = totalPages;
			locIndex = index - (locPage-1) * pagination_rows;
			//console.log("Found", symbol, "at", index, "locpage", locPage, "locindex", locIndex)
			defaultSearch = "";
			useAndStoreDefault = true;
		}
	}

	const [newRarity, setRarity] = useStateWithStorage('newRarity', 0, {useAndStoreDefault});
	const [tier, setTierFilter] = useStateWithStorage(tableId+'tier', 0, {useAndStoreDefault: useAndStoreDefault});

	const isRaritySelected = (r: number) => {
		if (r==0) return newRarity==0;
		else if (props.hideRarityFilter) return true;
		return ((newRarity as number)&(2<<r))>0;
	}

	const toggleRarity = (r: number) => {
		if (r==0 || props.hideRarityFilter) setRarity(0);
		else setRarity(newRarity^(2<<r));
	}

	let data = isRaritySelected(0) ? [...props.data] : props.data.filter(x=>isRaritySelected(x.rarity));
	data = tier == 0 ? data : data.filter(x=>x.customTier > 0 ? x.customTier == tier : x.tier == tier);

	const [loggedSearch, setLoggedSearch] = React.useState(false);
	const [searchFilter, setSearchFilter] = useStateWithStorage('searchFilter', props.clearSearch ? "" : defaultSearch, {useAndStoreDefault: props.clearSearch ?? useAndStoreDefault});
	const [filterType, setFilterType] = useStateWithStorage(tableId+'filterType', 'Any match');
	const [column, setColumn] = useStateWithStorage(tableId+'column', null, {useAndStoreDefault});
	const [direction, setDirection] = useStateWithStorage(tableId+'direction', null);
	const [pagination_page, setPaginationPage] = useStateWithStorage(tableId+'paginationPage', locPage === undefined ? 1 : locPage, {useAndStoreDefault: locPage !== undefined ? true : useAndStoreDefault});
	const [selectedIndex, setSelectedIndex] = useStateWithStorage(tableId+'selIdx', props.id=="captainHistory" ? 1 : (locIndex === undefined ? -1 : locIndex), {useAndStoreDefault: locIndex !== undefined ? true : useAndStoreDefault});
	const [secondaryColumn, setSecondaryColumn] = React.useState<string|undefined>(undefined);

	const setTier = (tier: number) => {
		setPaginationPage(1);
		setTierFilter(tier);
	}

	// We only sort here to store requested column and direction in state
	//	Actual sorting of full dataset will occur on next render before filtering and pagination
	function handleSort(clickedColumn, pseudocolumns, secondaryColumn?) {
		let sortConfig: IConfigSortData = {
			field: clickedColumn,
			direction: direction
		};

		if (secondaryColumn) {
			sortConfig.secondary = {
				field: secondaryColumn,
				direction: 'descending'
			}
		}

		if(pseudocolumns) {
			if(pseudocolumns.includes(column)) {
				sortConfig.field = column;
			} else {
				sortConfig.direction = null;
			}
			sortConfig.rotateFields = pseudocolumns;
		} else {
			if(clickedColumn !== column) {
				// sort newRarity and skills descending first by default
				sortConfig.direction = 'ascending';
			}
		}

		const sorted: IResultSortDataBy = sortDataBy(data, sortConfig);

		setSelectedIndex(locIndex === undefined ? -1 : locIndex);
		setSecondaryColumn(secondaryColumn);
		setColumn(sorted.field);
		setDirection(sorted.direction);
		setPaginationPage(1);
	}

	function onChangeFilter(value) {
		value = (value as string).replaceAll("“", "\"").replaceAll("”","\"");
		setSearchFilter(value);
		setPaginationPage(1);
	}

	function renderTableHeader(column: any, direction: 'descending' | 'ascending' | null): JSX.Element {
		return (
			<Table.Row>
				{props.config.map((cell, idx) => (
					<Table.HeaderCell
						key={idx+"h"}
						width={cell.width as any}
						sorted={((cell.pseudocolumns && cell.pseudocolumns.includes(column)) || (column === cell.column)) ? direction : null}
						className={cell.class}
						onClick={() => handleSort(cell.column, cell.pseudocolumns, cell.secondaryColumn)}
					>
						{cell.title}{cell.pseudocolumns?.includes(column) && <><br/><small>{column}</small></>}
					</Table.HeaderCell>
				))}
			</Table.Row>
		);
	}

	// Sorting
	if (column) {
		let sortConfig: IConfigSortData = {
			field: column,
			direction: direction,
			keepSortOptions: true
		};
		if (secondaryColumn) {
			sortConfig.secondary = {
				field: secondaryColumn,	
				direction: "descending"
			}
		}
		// Use original dataset for sorting
		const sorted: IResultSortDataBy = sortDataBy([...props.data], sortConfig);
		data = isRaritySelected(0) ? sorted.result : sorted.result.filter(x=>isRaritySelected(x.rarity));
		data = tier == 0 ? data : data.filter(x=>x.tier == tier);
	}

	// Filtering
	let filters = [];
	if (searchFilter) {
		let grouped = searchFilter.split(/\s+OR\s+/i);
		grouped.forEach(group => {
			let cheat = group.split(" ");
			for (let i=0; i<cheat.length; i++) {
				const old = cheat[i];
				if (!cheat[i].includes(":")) {
					cheat[i] = cheat[i].replace("<",":<").replace(">",":>");
					if (cheat[i] == old) {
						cheat[i] = cheat[i].replace("=", ":");
					}
				}
			}
			filters.push(SearchString.parse(cheat.join(" ")));
		});
	}
	data = data.filter(row => props.filterRow(row, filters, filterType));

	// Pagination
	let activePage = pagination_page;
	let totalPages = Math.ceil(data.length / pagination_rows);
	if (activePage > totalPages) activePage = totalPages;
	data = data.slice(pagination_rows * (activePage - 1), pagination_rows * activePage);

	React.useEffect(() => {
		if (loggedSearch) {
			return;
		}
		if (!props.showTierFilter) {
			setTierFilter(0);
		}
		if (props.hideRarityFilter) {
			setRarity(0);
		}
		setLoggedSearch(true);
		let urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has('search')) {
			let search = urlParams.get('search');
			if (search) {
				ReactGA.event({category:"Search", action:search});
			}
		}
	}, []);

	React.useEffect(() => {
		setTimeout(() => {
			if (selectedIndex == -1 || props.id=="captainHistory") {
				return
			}
			let element = document.getElementById("row"+selectedIndex);
			if (element) {
				let top = element.getBoundingClientRect().top;
				let expandedElement = document.getElementById("row"+selectedIndex+"e");
				if (top > 60 && (!expandedElement || expandedElement.getBoundingClientRect().bottom < window.innerHeight)) {
					return;
				}
				let elementHeight = element.getBoundingClientRect().height;
				let expandedHeight = expandedElement ? expandedElement.getBoundingClientRect().height : 0;
				if (window.innerHeight < elementHeight + expandedHeight - 60 || top < 60) {
					window.scrollTo({
						behavior: "smooth",
						top: -60 + element.getBoundingClientRect().top + window.pageYOffset
					});
				} else {
					window.scrollTo({
						behavior: "smooth",
						top: window.pageYOffset + expandedElement.getBoundingClientRect().bottom - window.innerHeight
					});
				}
			}
		},100);
	}, [selectedIndex])

	return (
		<div>
			{!props.hideSearch && <div className="searchableTableSearch">
				<Input
					style={{ width: ((isMobile && !isTablet) || props.fullSearchBar) ? '100%' : '50%' }}
					iconPosition="left"
					placeholder="Search..."
					value={searchFilter}
					onChange={(e, { value }) => {setSelectedIndex(-1); onChangeFilter(value);}}>
						<input />
						<Icon name='search' />
						<Button icon onClick={() => {setSelectedIndex(-1); onChangeFilter(''); navigate(window.location.pathname);}} >
							<Icon name='delete' />
						</Button>
				</Input>

				{props.showFilterOptions && (
					<span style={{ paddingLeft: '2em' }}>
						<Dropdown inline
									options={filterTypeOptions}
									value={filterType}
									onChange={(event, {value}) => {setSelectedIndex(-1); setFilterType(value as number);}}
						/>
					</span>
				)}

				{/* {props.showSearchExplanation && (<Popup wide trigger={<Icon name="help" />}
						header={'Advanced search'}
						content={props.explanation ? props.explanation : renderDefaultExplanation()}
					/>
				)} */}

				{props.showSearchExplanation && <Icon style={{cursor:"pointer"}} name="help" onClick={()=>navigate("/help")}/>}
			</div>}

			{!props.hideRarityFilter && <div className={(isMobileOnly ? "rfmobile " : "") + "rarityfilter"}>
				<span className="mono">Show:{' '}</span>
				<span className={"rarityfilter-0"+(isRaritySelected(0)?" rarityselected":"")} style={isRaritySelected(0)?{color:'white'}:{}} onClick={()=>toggleRarity(0)}>All</span>|
				<span className={"rarityfilter-5"+(isRaritySelected(5)?" rarityselected":"")} onClick={()=>toggleRarity(5)}>Legendary</span>{' '}
				<span className={"rarityfilter-4"+(isRaritySelected(4)?" rarityselected":"")} onClick={()=>toggleRarity(4)}>Super Rare</span>{' '}
				<span className={"rarityfilter-3 rare"+(isRaritySelected(3)?" rarityselected":"")} onClick={()=>toggleRarity(3)}>Rare</span>{' '}
				<span className={"rarityfilter-2 uncommon"+(isRaritySelected(2)?" rarityselected":"")} onClick={()=>toggleRarity(2)}>Uncommon</span>{' '}
				<span className={"rarityfilter-1 common"+(isRaritySelected(1)?" rarityselected":"")} onClick={()=>toggleRarity(1)}>Common</span>
			</div>}

			{props.showTierFilter && <div className={(isMobileOnly ? "rfmobile " : "") + "rarityfilter"}>
				<span className="mono">Tier:{' '}</span>
				<span className={"tierFilter"+(tier==0?" tierFilterSelected":"")} onClick={()=>setTier(0)}>All</span>|
				<span className={"tierFilter"+(tier==1?" tierFilterSelected":"")} onClick={()=>setTier(1)}>1</span>
				<span className={"tierFilter"+(tier==2?" tierFilterSelected":"")} onClick={()=>setTier(2)}>2</span>
				<span className={"tierFilter"+(tier==3?" tierFilterSelected":"")} onClick={()=>setTier(3)}>3</span>
				<span className={"tierFilter"+(tier==4?" tierFilterSelected":"")} onClick={()=>setTier(4)}>4</span>
				<span className={"tierFilter"+(tier==5?" tierFilterSelected":"")} onClick={()=>setTier(5)}>5</span>
				<span className={"tierFilter"+(tier==6?" tierFilterSelected":"")} onClick={()=>setTier(6)}>6</span>
				<span className={"tierFilter"+(tier==7?" tierFilterSelected":"")} onClick={()=>setTier(7)}>7</span>
				<span className={"tierFilter"+(tier==8?" tierFilterSelected":"")} onClick={()=>setTier(8)}>8</span>
				<span className={"tierFilter"+(tier==9?" tierFilterSelected":"")} onClick={()=>setTier(9)}>9</span>				
				<span className={"tierFilter"+(tier==10?" tierFilterSelected":"")} onClick={()=>setTier(10)}>10</span>
			</div>}

			{props.querySuggestions && <div className={(isMobileOnly ? "rfmobile " : "") + "rarityfilter"}>
				<span className="mono">Query suggestion:{' '}</span>
				<a className="blueLink querySug" onClick={() => {
					navigate(window.location.pathname + "?search=portal+crnow=100");
					logEvent("QueryLink", "query", "portal+crnow=100");
					onChangeFilter("portal crnow=100");
					toggleRarity(5);
					setTier(0);
					//window.location.reload();
				}}>Best retrievable crew</a>
			</div>}


			{/* <div style={{width:window.innerWidth,overflowX: "scroll"}}> */}
			<Table sortable celled selectable striped collapsing unstackable compact="very" className="highlightTable">
				<Table.Header>{renderTableHeader(column, direction)}</Table.Header>
				<Table.Body>{data.map((row, idx) => {
					let cn="";
					let scn="";
					if (idx==locIndex && locPage == pagination_page && props.getRowBackground != undefined) {
						if (((selectedIndex == idx) || (data.length==1 && totalPages == 1)) && props.renderExpandedRow) {
							cn = "highightTopRow";
							scn = "highlightBottomRow";
						} else {
							cn = "highlightRow";
						}
					}
					return <React.Fragment key={idx}>
						<Table.Row className={cn +" rowCN"} id={"row"+idx} key={"row"+idx} style={{ cursor: props.renderExpandedRow ? 'pointer' : 'default',
							backgroundImage: props.getRowBackground != undefined ? props.getRowBackground(row) : ""}}
							onClick={() => {
								if (!props.renderExpandedRow) {
									return;
								}
								if (props.id == "captainHistory" && row.year == 2100) {
									return;
								}
								if (idx==selectedIndex) {
									setSelectedIndex(-1);
								} else {
									setSelectedIndex(idx);
								}
							}}>
							{props.renderTableRow(row, idx)}
						</Table.Row>
						{((selectedIndex == idx) || (data.length==1 && totalPages == 1)) && props.renderExpandedRow &&
							<Table.Row id={"row"+idx+"e"} key={idx+'e'} style={{ cursor: 'default' }} className={"expanded-row "+scn}>
								{props.renderExpandedRow(row, idx)}
							</Table.Row>}
					</React.Fragment>
				})}</Table.Body>
				{!props.hideFooter && <Table.Footer>
					<Table.Row>
						<Table.HeaderCell colSpan={props.config.length} className={props.mobileWidth?"mobileWidth":""}>
							<Pagination
								className={props.mobileWidth?"mobileWidth":""}
								totalPages={totalPages}
								activePage={activePage}
								onPageChange={(event, { activePage }) => {
									setSelectedIndex(-1);
									setPaginationPage(activePage as number);
								}}
							/>
							{isMobile && <div style={{ paddingTop: '1em' }}>
								<span>
									Rows per page:{' '}
									<Dropdown
										inline
										defaultValue={props.defaultPerPage ? props.defaultPerPage.toString() : pagingOptions[0].text}
										options={pagingOptions}
										onChange={(event, {value}) => {
											setPaginationPage(1);
											setPaginationRows(value as number);
										}}
									/>
								</span>
							</div>}
							{!isMobile && <span style={{ paddingLeft: '2em' }}>
									Rows per page:{' '}
									<Dropdown
										inline
										defaultValue={props.defaultPerPage ? props.defaultPerPage.toString() : pagingOptions[0].text}
										options={pagingOptions}
										onChange={(event, {value}) => {
											setPaginationPage(1);
											setPaginationRows(value as number);
										}}
									/>
								</span>}
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>}
			</Table>
			</div>
		// </div>
	);
}

export function renderDefaultExplanation() {
	return (
		<div>
			<p>
				Search for crew by name, newRarity, trait, hidden trait, collection, nickname, flavor, exclusivity, skill or skill order and set. See additional ship battle keywords below.
				Every search term can be negated by prefixing '-' to exclude results. Additional prefixes: 
			</p>
			<p>
				<code>collection:</code> or <code>c:</code> to only show crew from the specified collection, <code>primary:</code> or <code>p:</code> to only show crew with the specified primary skill.{' '}
				Similarly, <code>secondary:</code> or <code>s:</code>, <code>tertiary:</code> or <code>t:</code>
			</p>
			<p>
				Examples:
			</p>
			<p>
				<code>p:dip cmd -sec</code> <p>shows all crew with diplomacy as the primary skill, that also have command but not security</p>
			</p>
			<p>
				<code>cmd/dip/ -t:sec</code> <p>shows all crew with cmd primary, dip secondary, and any tertiary besides sec</p>
			</p>
			<p>
				<code>cmd|dip/sec</code> <p>shows all cmd/dip and dip/cmd crew with a sec tertiary</p>
			</p>
			<p>
				Filter by best chance of retrieval now or later after a portal update with <code>crnow</code> and <code>crlater</code>. Example:{' '}
				<code>crnow=100 crnow&#62;crlater</code>
			</p>
			<p>
				<p>Filter for exclusive crew with the keywords:</p>
				<code>portal</code>, <code>gauntlet</code>, <code>voyage</code>, <code>fusion</code>, <code>honor</code>, <code>campaign</code>, <code>collection</code>, <code>mega</code> or <code>giveaway</code>
			</p>
			<p>
				<p>Filter for specific TV series:</p>
				<code>tos</code>, <code>tas</code>, <code>tng</code>, <code>ds9</code>, <code>voy</code>, <code>ent</code>, <code>dsc</code>, <code>pic</code>, <code>low</code> (or <code>lds</code>)                
			</p>
			<p>
				<p>Specific keywords for the ship battle table:</p>
				<code>att</code>, <code>ev</code>, <code>acc</code>, <code>trigger</code> or <code>tr</code>, <code>ability</code> or <code>a</code>, <code>penalty</code> or <code>pe</code>, <code>phases</code>, <code>charges</code>, <code>limit</code> or <code>l</code>, <code>init</code> or <code>i</code>, <code>cooldown</code> or <code>cd</code>, <code>duration</code> or <code>d</code>, <code>cycle</code> or <code>cy</code>, <code>uptime</code> or <code>up</code>.
				<br/><br/>
				<p>Query example for instant damage crew with at least 200% damage, initialized before second 6, with no limit or trigger, and a cycle of less than 20 seconds (duration+cooldown, the time between each instant damage):</p>
				<code>immediate damage ability&gt;=200 init&lt;6 -limit -trigger cycle&lt;20</code>
			</p>
		</div>
	);
}